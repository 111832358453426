import * as actionTypes from "./actionTypes";

export function dashboard() {
  return {
    type: actionTypes.DASHBOARD
  };
}

export function login() {
  return {
    type: actionTypes.LOGIN
  };
}
export function getRecentChecks(data) {
  return {
    type: actionTypes.GET_RECENT_CHECKS,
    payload: data
  };
}
export function setRecentChecks(data) {
  return {
    type: actionTypes.SET_RECENT_CHECKS,
    payload: data
  };
}
export function getPendingPayableReport(data) {
  return {
    type: actionTypes.GET_PENDING_PAYABLE_CHECK,
    payload: data
  };
}
export function setPendingPayableReport(data) {
  return {
    type: actionTypes.SET_PENDING_PAYABLE_CHECK,
    payload: data
  };
}
export function getCustomerServiceReport(data) {
  return {
    type: actionTypes.GET_CUSTOMER_SERVICE,
    payload: data
  };
}
export function setCustomerServiceReport(data) {
  return {
    type: actionTypes.SET_CUSTOMER_SERVICE,
    payload: data
  };
}
export function getVendorDownloadLinks(data) {
  return {
    type: actionTypes.GET_VENDOR_DOWNLOAD_LINK,
    payload: data
  };
}
export function vendorDownloadLinkSuccess(data) {
  return {
    type: actionTypes.VENDOR_DOWNLOAD_LINK_SUCCESS,
    payload: data
  };
}
export function dashboardData(data) {
  return {
    type: actionTypes.DASHBOARD_DATA,
    payload: data
  };
}
