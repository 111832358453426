import React, { Component } from "react";
import { PDFObject } from "react-pdfobject";
import { env, ENV } from "../../common/sysco-ui-components/config/constant";
import  cookie from "react-cookies";
import history from "../../utils/history";
import { getToken } from "../../config/apiConfig";
import { connect } from "react-redux";

class Policy_Document extends Component {
  static displayName = 'Policy_Document';
  constructor(props) {
    super(props);
    this.state = {
      url:`https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${
        ENV()==='-dev'?"27522250":ENV()==='-stg'?'27550542':ENV()==='-prod'?'215822629':''
        }/content?OTDSTicket=${cookie.load("serviceToken")}`
    };
  }

    async componentDidMount(){
      !localStorage.getItem("authToken") && history.push("/")
      let data = await getToken()
      this.setState({
        url: this.props.data.urlLink
      })
      // `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${ENV()==='-dev'?"27522250":ENV()==='-stg'?'27550542':ENV()==='-prod'?'215822629':''}/content?OTDSTicket=${data?data:cookie.load("serviceToken")}`
      // https://les$-stg.sysco.com/otcs/cs.exe/api/v1/nodes/27550542/content?OTDSTicket=
    }
  render() {
    return <div style={{height:"86Vh"}}><PDFObject containerId={this.props.data.id} height="90Vh"  url={this.state.url} /></div>;
  }
}

export default connect()(Policy_Document);
