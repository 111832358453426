import { put } from "redux-saga/effects";
import request from "../../utils/request";
import * as actions from "../actions/documentDetailAction";
import { call } from "redux-saga/effects";
import { authorization, API_URL } from "../../config/apiConfig";
import { toast } from "react-toastify";
import {apigeeTokenGeneration }from "../../utils/request"
export function* getDocumentDetail(action) {
  const requestURL = `${API_URL}supplier-web/get-doc-attrs?docId=${
    action.payload.docId
  }&catName=${
    action.payload.catName === "Check-ACH"
      ? "SUPP WEB BSCC AP Payment"
      : "SUPP WEB BSCC AP Merchandise"
  }`;
  const requestAttrs = {
    method: "GET",
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization: authorization()
    }
  };
  try {
    const response = yield call(request, requestURL, requestAttrs);
    let data = response.rows[0].categories;
    yield put(actions.documentDetailSuccess(data ? data : []));
  } catch (err) {
   // console.log(err);
    if (err.toString().includes('401')) {
      apigeeTokenGeneration(action);
     }else{
      toast.error("Failed to load Document details, please reload.",{
        autoClose: false
      })
    }
  }
}
