import * as actionTypes from "./actionTypes";

export const addTab = (title, eventKey,payload) => {
  const data = {
    title: title,
    eventKey: eventKey,
    data:payload
  };
  return {
    type: actionTypes.ADDTAB,
    payload: data
  };
};

export const delTab = eventKey => {
  const data = {
    eventKey: eventKey
  };
  return {
    type: actionTypes.DELTAB,
    payload: data
  };
};
