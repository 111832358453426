import React from 'react';

import { Button } from "react-bootstrap";
import ReactTable from "react-table";
import { getpagination, downloadReport } from '../../common/sysco-ui-components/services/commonServices';
import { store } from '../..';
import { addTab } from '../../common/sysco-ui-components/store/actions/tabViewAction';

import { connect } from "react-redux";
import { compose } from "redux";
import { SearchAction, SearchResult } from '../../store/actions/searchAction';
import { API_URL_SW, API_URL, authorization } from '../../config/apiConfig';
import Axios from 'axios';
import docDetailsContainer from '../../containers/docDetailsContainer';
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import { SyncOutlined } from '@ant-design/icons';
class SearchResultsComponent extends React.Component {
    state = {
        submitFlag: true,
        checkList: [],
        query1:{
            "criteria" : [
            {
            "catName":"BSCC AP Merchandise",
            "attrName": "Document Type",
            "attrValue": this.props.data.docType.replace('-','/').split(','),
            "modifier": ["Is"],
            "attrType" : "String"
            },{
            "catName":"OpCo Global",
            "attrName": "OpCo Number",
            "attrValue": ["000"],
            "modifier": ["Is"],
            "attrType" : "String"
            }, {
            "catName":"BSCC AP Merchandise",
            "attrName": "Vendor Number",
            "attrValue": localStorage.getItem("subvendorList")?localStorage.getItem("subvendorList").split(","):[this.props.data.venNo],
            "attrType" : "String"
            }, {
            "catName":"BSCC AP Merchandise",
            "attrName": "Is Secure",
            "attrValue": ["True"],
            "modifier": ["Is Not"],
            "attrType" : "String"
            },{
            "catName":"BSCC AP Merchandise",
            "attrName": "Vendor Invoice Number",
            "attrValue": [" "],
            "modifier": ["Is"],
            "attrType" : "String"
            }
            ],
            "condition" : "AND",
            "resultsCount" : 5000,
            "templateName" : "SUPP WEB BSCC AP Merchandise"
            },
            query2:{
                "criteria" : [
                {
                "catName":"BSCC AP Payment",
                "attrName": "Document Type",
                "attrValue": this.props.data.docType.replace('-','/').split(','),
                "modifier": ["Is"],
                "attrType" : "String"
                },{
                "catName":"OpCo Global",
                "attrName": "OpCo Number",
                "attrValue": ["000"],
                "modifier": ["Is"],
                "attrType" : "String"
                }, {
                "catName":"BSCC AP Payment",
                "attrName": "Vendor Number",
                "attrValue": localStorage.getItem("subvendorList")?localStorage.getItem("subvendorList").split(","):[this.props.data.venNo],
                "modifier": ["Is"],
                "attrType" : "String"
                }, {
                "catName":"BSCC AP Payment",
                "attrName": "Is Secure",
                "attrValue": ["True"],
                "modifier": ["Is Not"],
                "attrType" : "String"
                },
                // {
                // "catName":"BSCC AP Payment",
                // "attrName": "Vendor Invoice Number",
                // "attrValue": [" "],
                // "modifier": ["Is"],
                // "attrType" : "String"
                // },
                {
                 "catName":"BSCC AP Payment",
                  "attrName": "Check ACH Number",
                  "attrValue": this.props.data.checkNo?[this.props.data.checkNo]:[" "],  
                //   "attrValue": this.props.data.docType.toString().replace('/','-').includes("Check")?[this.props.data.checkNo]:[" "],   
                  "modifier": ["Is"],
                  "attrType" : "String"
                }
                ],
                "condition" : "AND",
                "resultsCount" : 5000,
                "templateName" : "SUPP WEB BSCC AP Payment"
                },
                query3:{
                    "criteria" : [
                    {
                    "catName":"BSCC AP Merchandise",
                    "attrName": "Document Type",
                    "attrValue": ["Last Confirmed PO"],
                    "modifier": ["Is"],
                    "attrType" : "String"
                    },{
                    "catName":"OpCo Global",
                    "attrName": "OpCo Number",
                    "attrValue": ["000"],
                    "modifier": ["Is"],
                    "attrType" : "String"
                    }, {
                    "catName":"BSCC AP Merchandise",
                    "attrName": "Vendor Number",
                    "attrValue": localStorage.getItem("subvendorList")?localStorage.getItem("subvendorList").split(","):[this.props.data.venNo],
                    "modifier": ["Is"],
                    "attrType" : "String"
                    }, 
                    {
                    "catName":"BSCC AP Merchandise",
                    "attrName": "Is Secure",
                    "attrValue": ["True"],
                    "modifier": ["Is Not"],
                    "attrType" : "String"
                    },
                    {
                    "catName":"BSCC AP Merchandise",
                    "attrName": "Vendor Invoice Number",
                    "attrValue": [],
                    "modifier": ["Is"],
                    "attrType" : "String"
                    }
                    ],
                    "condition" : "AND",
                    "resultsCount" : 5000,
                    "templateName" : "SUPP WEB BSCC AP Merchandise"
                    },
                    query4: {
                        "criteria": [
                            {
                                "catName": "BSCC AP Expense",
                                "attrName": "Document Type",
                                "attrValue": ["Expense Invoice"],
                                "modifier": ["Is"],
                                "attrType": "String"
                            }, {
                                "catName": "OpCo Global",
                                "attrName": "OpCo Number",
                                "attrValue": ["000"],
                                "modifier": ["Is"],
                                "attrType": "String"
                            }, {
                                "catName": "BSCC AP Expense",
                                "attrName": "Vendor Number",
                                "attrValue": localStorage.getItem("subvendorList") ? localStorage.getItem("subvendorList").split(",") : [],
                                "modifier": ["Is"],
                                "attrType": "String"
                            },
                            {
                                "catName": "BSCC AP Expense",
                                "attrName": "Is Secure",
                                "attrValue": ["True"],
                                "modifier": ["Is Not"],
                                "attrType": "String"
                            },
                            {
                                "catName": "BSCC AP Expense",
                                "attrName": "Vendor Invoice Number",
                                "attrValue": [" "],
                                "modifier": ["None"],
                                "attrType": "String"
                            }, 
                            {
                                "catName": "BSCC AP Expense",
                                "attrName": "Order Control Number",
                                "attrValue": [],
                                "modifier": ["None"],
                                "attrType": "String"
                            },
                            {
                                "catName": "BSCC AP Expense",
                                "attrName": "BSCC PO Number",
                                "attrValue": [],
                                "modifier": ["None"],
                                "attrType": "String"
                            }
                        ],
                        "condition": "AND",
                        "resultsCount": 5000,
                        "templateName": "SUPP WEB BSCC AP Expense"
                    }
    }

  componentDidMount(){
  }
  handleCheckBox = (e) => {
    if (!this.state.checkList.includes(e.target.value)) {
        this.setState({ checkList: [...this.state.checkList, e.target.value] })
    }
    else {
        let double = this.state.checkList.filter(el => el !== e.target.value);

        this.setState({ checkList: double })
    }
}
  
    showDocDetails = (docName) => {
        this.props.showDocDetails(docName);
    }

    render() {
        return (
            <div>
                {/* <Header /> */}
                <div className="tablecontent tabViewContent" >
                    <div className="filter-list">
                        <SearchResultTable
                            query1={this.state.query1}
                            query2={this.state.query2}
                            query3={this.state.query3}
                            query4={this.state.query4}
                            docType={this.props.data.docType}
                            ResultHide={this.ResultHide}
                            submitFlag={this.state.submitFlag}
                            showDocDetails={this.showDocDetails}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
class SearchResultTable extends React.Component {
    state = {
        resultCheckList: [],
        tableDisplayData:[],
        tableRawData:[],
        selectAll:false,
        zipResPayload:[],
        searchRes:[],
        tempData1:{},
        tempData2:{},
        expenseInvoiceTempData:{},
        Spinner:false
    }

     componentDidMount(){
         this.getSerachRes();
     }
     getSerachRes=()=>{

        this.state.tempData1=Object.assign({},this.props.query1)
        this.state.tempData2=Object.assign({},this.props.query2)
        this.state.expenseInvoiceTempData=Object.assign({},this.props.query4);
        var index =this.state.tempData1.criteria.length!==0 ? this.state.tempData1.criteria[0].attrValue.findIndex(item => item === "Vendor Invoice"):-1;
        if(index>-1){
            this.state.tempData1.criteria[0].attrValue.splice(index, 1,...["Vendor Invoice", "EDI Vendor Invoice", "EDI Vendor Invoice - Auto", "EDI Vendor Invoice - Non Auto","Vendor Invoice - Produce"])
            this.state.tempData2.criteria[0].attrValue.splice(index, 1,...["Vendor Invoice", "EDI Vendor Invoice", "EDI Vendor Invoice - Auto", "EDI Vendor Invoice - Non Auto","Vendor Invoice - Produce"])            
          
        }
          let data1 = this.state.tempData1.criteria && this.state.tempData1.criteria.filter(val => val.attrValue.length !== 0);
          let data2 = this.state.tempData2.criteria && this.state.tempData2.criteria.filter(val => val.attrValue.length !== 0);
          let expenseInvoiceData = this.state.expenseInvoiceTempData.criteria && this.state.expenseInvoiceTempData.criteria.filter(val => val.attrValue.length !== 0);
          this.state.tempData1.criteria = data1;
          this.state.tempData2.criteria = data2;
          this.state.expenseInvoiceTempData.criteria = expenseInvoiceData;
           let formData = new FormData();
           formData.append("query1", JSON.stringify(this.state.tempData1));
           formData.append("query2", JSON.stringify(this.state.tempData2));           
           this.props.docType.includes("Last Confirmed PO") && this.props.query3 && formData.append("query3", JSON.stringify(this.props.query3))
           this.props.docType.includes("Expense Invoice") && this.props.query4 && formData.append("query4", JSON.stringify(this.props.query4));
            this.setState({Spinner:true});
           Axios({
            url: `${API_URL_SW}/get-search-results`,
            method: "POST",
            headers: {authorization: authorization()},
            data:formData
          })
            .then(res => {
                this.setState({tableRawData:res.data.rows[0].searchResultsLists,Spinner:false},()=>{
              (this.state.tableRawData &&  this.state.tableRawData.length!==0) ? this.generateData():this.setState({tableDisplayData:[]}) })
            })
            .catch(err => {
                this.setState({Spinner:false})
                toast.error("Failed to load Serach results, Please try again.")
            });
     }

    onGeneratedRow(columnsResult,type) {
        var jsonData = {};
        var name="";
        if(columnsResult.DocumentType==="Check/ACH"){
            name=`${columnsResult.DocumentType.toString().replace(/\//g, "_")}_[${columnsResult.VendorNumber}-${columnsResult.CheckNumber}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        }else if(columnsResult.DocumentType==="Last Confirmed PO"){
            name=`${columnsResult.DocumentType}_[${columnsResult.VendorNumber}-${columnsResult.PONumber}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        }else{
            name=`${columnsResult.DocumentType?columnsResult.DocumentType.toString().replace(/\//g, "_"):""}_[${columnsResult.VendorNumber}-${columnsResult.InvoiceNumber && columnsResult.InvoiceNumber.length!==0 ?columnsResult.InvoiceNumber[0]:""}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        }
        jsonData["dataId"] = columnsResult.nodeId.toString();
        jsonData["name"] = name;
        jsonData["docName"] = columnsResult.docName;
        this.state.zipResPayload = this.state.zipResPayload.filter(item => {
          return item.dataId !== columnsResult.nodeId.toString();
        });
        type==="add" && this.state.zipResPayload.push(jsonData);
        this.setState({ zipResPayload: this.state.zipResPayload });
      }
    zipFile=()=>{
        // this.setState({selectAll:false})
        downloadReport('supplier-web/zip-files','search-results.zip',"POST",this.state.zipResPayload,API_URL)
    }
    generateData=()=>{
        let tableData=[]
         this.state.tableRawData &&  this.state.tableRawData.map(val=>{
          var category={}
          val.docCat[0].catAttrs.map(value=>{
            // tabledata.push()
            var data=value.attrName.replace(/ /g,"")
            if(data==="CheckNumber" &&  value.attrValues===""){
            }else
             Object.assign(category, {[data]: value.attrValues})  
          })
          Object.assign(category, {nodeId: val.docId})
          Object.assign(category, { docName: val.docName })
          tableData.push(category)
        })
        this.setState({tableDisplayData:tableData},()=>{
         
        })
      }
   
    checkBoxClicked = (e, i) => {
     if (!this.state.resultCheckList.includes(e.nodeId)) {
    this.setState({ resultCheckList: [...this.state.resultCheckList,e.nodeId] })
    this.onGeneratedRow(e,"add")
}
    else {
    let double = this.state.resultCheckList.filter(el => el !== e.nodeId);
    this.setState({ resultCheckList: double })
    this.onGeneratedRow(e,"remove")
}


}
ResultHide = item => {
    if (item === "myResult") {
        this.props.ResultHide();
    }
};
    showDetails = (data) => {
        // this.props.showDocDetails(data.docName);
        store.dispatch(addTab("Document Details : "+data.nodeId, docDetailsContainer,{id:data.nodeId,docType:data.DocumentType[0].replace("/", "-")}));
        // store.dispatch(addTab("Document Details", `DocDetails/${data.nodeId}/${data.DocumentType[0].replace("/", "-")}`));
        // history.push(`/DocDetails/${data.nodeId}/${data.DocumentType[0].replace("/", "-")}`);

    }
    handleAll = (e) => {
        if (this.state.resultCheckList.length === this.state.tableDisplayData.length) {
            this.setState({ resultCheckList: [],selectAll:false,zipResPayload:[] })
        }
        else {
            let newList = []
            this.state.tableDisplayData.map((item, i) => {
                newList.push(item.nodeId);
                this.onGeneratedRow(item,"add")
            })
            this.setState({ resultCheckList: newList,selectAll:true })
        }

    }
    render() {
        const columns = [
            {
                Header: (
                    <input
                      type="checkbox"
                      onChange={this.handleAll}
                      checked={this.state.selectAll}
                    />),
                Cell: row => {
                    let data = row.original;
                    return (
                        <div className="custom-table" style={{ textAlign: "center" }}>
                            <input type="checkbox"
                                checked={this.state.resultCheckList.includes(data.nodeId)}
                                style={{ float: "center" }}
                                value={data}
                                onChange={() => { this.checkBoxClicked(data, data.nodeId) }} />
                        </div>
                    );
                },
                width:50
            },
            {
                Header: "Document Type",
                accessor: "DocumentType",
                Cell: row => {
                    // let index = row.index;
                    // let supplierName = row.original.vendorName;
                    return (

                        <div>

                            <a style={{ textDecoration: "underLine" }}
                                onClick={() => { this.showDetails(row.original) }}
                                >
                                {/* {this.props.docTypes.map((item) => {
                                    if (row.original.DocumentType == item.key) {
                                        return (
                                            <div>{item.label}</div>
                                        )
                                    }
                                })} */}
                                 <div>{row.original.DocumentType }</div>
                            </a>

                        </div>
                    );
                }

            },
            {
                Header: "Vendor Invoice #",
                accessor: "InvoiceNumber",
                Cell: row => {
                    return (
                        <span >{(row.value && row.value.length!==0)?row.value.toString():''}</span>
                        // <div>
                        //     {row.original.InvoiceNumber.map((item) => {
                        //         return (<div>{item}</div>)

                        //     })}

                        // </div>
                    )
                }
            },
            {
               
                accessor: "PONumber",
                Header: "PO #"
            },
            {
                Header: "OC #",
                accessor: "OrderControlNumber",
                Cell: row => {
                    return (
                        <div>{row.original.OrderControlNumber?row.original.OrderControlNumber.toString():''}</div>
                        // <div>
                        //     {row.original.OrderControlNumber.map((item) => {
                        //         return (<div>{item}</div>)

                        //     })}

                        // </div>
                    )
                }
            },
            {
                Header: "Voucher #",
                accessor: "VoucherNumber"
            },
            {
                Header: "Check/ACH #",
                accessor: "CheckNumber"
            },
            {
                Header: "Payment Date",
                accessor: "PaymentDate"
            },
            {
                Header: "Payment Amount",
                accessor: "PaymentAmount"
            }

        ];

        return (
            <div>
                {this.props.submitFlag ? (
                    <div className="resultTable">
                        <div style={{ textAlign: "left" }}>
                        <div style={{display:"flex",justifyContent:"space-between"}}>   <h4 className="resultTab">Search Result</h4>
                            <SyncOutlined className="refreshStyle"
                             onClick={event => {
                             event.stopPropagation();
                             this.getSerachRes();
                            }}
                             /></div>

                            <Spin spinning={this.state.Spinner} tip={"Search results loading..."}><div className="upperBodyTable2">
                                 <div style={{width:"100%"}}>
                                  <Button bsStyle="primary" style={{float:"right"}} disabled={this.state.resultCheckList.length===0} onClick={()=>this.zipFile()}>Download Selected Document</Button></div>  
                                    <ReactTable
                                        // resizable={false}
                                        className="-striped"
                                        defaultSorted={[
                                            {
                                              id: "DocumentType",
                                              desc: false
                                            }
                                          ]}
                                        data={this.state.tableDisplayData}
                                        columns={columns}
                                        defaultPageSize={5}
                                        pageSizeOptions={getpagination(this.state.tableDisplayData && this.state.tableDisplayData.length)}
                                    />
                                </div></Spin>
                        
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export function mapDispatchToProps(dispatch) {
    // eslint-disable-line
    return {
        handleSearch: data => { dispatch(SearchAction(data)) },
        showDocDetails: data => { dispatch(SearchResult(data)) }

    };
}

function mapStateToProps(state) {
    return state;
}

const withConnect = connect(
    mapStateToProps,
    mapDispatchToProps
);

export default compose(withConnect)(SearchResultsComponent);