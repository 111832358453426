import cookie from "react-cookies";

export const headerItems = [
  "dashboard",
  "File-Upload",
  "OSD-Upload",
  "search",
  "administration",
  "invoice",
  "reports",
  "snapshots",
  "report",
  "BSCC-Reports",
  "upload-document",
  "barcode-printer",
  "request",
  "user-guide"
];

export function environment() {
  if (window.location.hostname.includes("dev")) {
    return "-dev";
  } else if (window.location.hostname.includes("staging")) {
    return "-staging";
  } else if (window.location.hostname.includes("stg")) {
    return "-stg";
  } else if (window.location.hostname.includes("prod")) {
    return "-prod";
  } else if (!window.location.hostname.includes("dev") && !window.location.hostname.includes("stg") && !window.location.hostname.includes("localhost")) {
    return "-prod";
  } else {
    return "-dev";
  }
}
export const REFRESH = "/refresh";
export const ENV = () => {
  return cookie.load("env") ? cookie.load("env") : "-dev";
};
export function PROD() {
  if (ENV() === "-dev") {
    return "-np";
  } else return "";
}
export const LOGIN = `${window.location.protocol}//ice${environment() === '-prod' ? '' : environment()}.cloud.sysco.net/`;
export const LOCAL = "localhost";
export const DEV = ".cloud.sysco.net";
export const STORAGE_PATH = window.location.hostname.includes(LOCAL)
  ? LOCAL
  : DEV;
export const AUTHORIZATION = () => {
  return cookie.load("token");
};

export const BASE_URL = `${window.location.protocol}//pcm-sysco-ot-service${
  ENV() === "-stg" ? "-staging" : ENV()
  }.pcm${PROD()}.us-east-1.aws.sysco.net/`;
export const headers = () => {
  return {
    "Content-Type": "application/json",
    authToken: AUTHORIZATION()
  };
};

export function env() {
  if (ENV() === '-dev') {
    return '-dev2';
  } else if (ENV() === '-stg') {
    return ENV();
  } else if (ENV() === '-prod') {
    return '';
  }
}

export function envDownload() {
  if (environment() === '-dev') {
    return '-dev2';
  } else if (environment() === '-stg') {
    return '-stg';
  } else if (environment() === '-prod') {
    return '';
  }
}

export const SUPPLIER_SUITE_URL = {
  DEV : "https://dev.sss.mdm-supplier-np.us-east-1.aws.sysco.net/",
  STG : "https://qa.sss.mdm-supplier-np.us-east-1.aws.sysco.net/",
  PRD : "https://supplierportal.sysco.com/"
}