
import * as actionTypes from "./actionTypes";

export function getResponseDetails(data) {
    return {
        type: actionTypes.GET_RESPONSE_DETAIL,
        payload: data
    };
}
export function responseDetailSuccess(data) {
    return {
        type: actionTypes.RESPONSE_DETAIL_SUCCESS,
        payload: data
    };
}


export function getAttachments(data) {
    return {
        type: actionTypes.GET_ATTACHMENTS,
        payload: data
    };
}
export function requestAttachmentData(data){
    return{
        type:actionTypes.REQUEST_ATTACHMENT_DATA,
        data:data
    }
}