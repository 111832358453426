import { SEARCH_ACTION, SEARCH_DATA, SEARCH_RESULT, SEARCH_RESULT_DATA, SEARCH_LOCATE_DATA } from "./actionTypes";


export function SearchAction(data) {
    return {
        type: SEARCH_ACTION,
        data
    }
}

export function SearchData(data) {
    return {
        type: SEARCH_DATA,
        data
    }
}

export function SearchResult(data) {
    return {
        type: SEARCH_RESULT,
        data

    }
}

export function SearchResultData(data) {
    return {
        type: SEARCH_RESULT_DATA,
        data
    }
}

export function SearchLocateData(data) {
    return {
        type: SEARCH_LOCATE_DATA,
        data
    }
}