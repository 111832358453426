import React, { Component } from "react";
import "./styles.css";
import { Row, Col, Button } from "react-bootstrap";
import cookie from "react-cookies";
import Axios from "axios";
import { API_URL_SW, authorization, DP_ENV, authorizationJwt } from "../../config/apiConfig";
import { toast } from "react-toastify";
import history from "../../utils/history";
import moment from "moment";
import { Switch } from 'antd';
import {store} from '../../'

class ChangePassComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPass: "",
      newPass: "",
      reEnterNewPass: "",
      passwordPolicyError: "",
      showPassword: false,
      invalidCred: false
    };
  }
  handleChange = e => {
    this.setState({ currentPass: e.target.value, invalidCred:false });
  };
  handleChange1 = e => {
    this.setState({ newPass: e.target.value });
  };
  handleChange2 = e => {
    this.setState({ reEnterNewPass: e.target.value });
  };
  onShowpassword = val => {
    this.setState({ showPassword: val });
  };

  logOut = () => {
      sessionStorage.clear();
      localStorage.clear();
      cookie.remove("token", { path: '/' });
      cookie.remove("userId", { path: '/' });
      cookie.remove("userName", { path: '/' });
      cookie.remove("authToken", { path: '/' });
      cookie.remove("serviceToken", { path: '/' });
      cookie.remove("jwt_token", { path: '/' });
      cookie.remove("name", { path: '/' });
      cookie.remove("accountExpirationTime", { path: '/' });
      store.dispatch({type:"CLEAR_TAB"})
      store.dispatch({type:"SET_RECENT_CHECKS",payload:[]})
      store.dispatch({type:"SET_PENDING_PAYABLE_CHECK",payload:[]})
      store.dispatch({type:"SET_CUSTOMER_SERVICE",payload:[]})
      store.dispatch({type:"VENDOR_DOWNLOAD_LINK_SUCCESS",payload:[]})
      store.dispatch({type:"SEARCH_DATA",data:[]})
      store.dispatch({type:"LOGIN_FAILED_CHANGE_AFTER_RESET",data:{}})
      store.dispatch({type:"LOGIN_FAILED_PASSWORD_EXPIRED",data:{}})
      history.push('/')
  };
  changePassword = () => {
    if (this.state.currentPass !== this.state.newPass) {
      Axios({
        url: `${API_URL_SW}/change-password?userId=${cookie.load("userName")}`,
        method: "POST",
        headers: {
           authorization: authorization()
        },
        data: {
          oldPassword: this.state.currentPass,
          newPassword: this.state.newPass
        }
      })
        .then(res => {
          if (res.data.rows[0].message === "SUCCESS") {
            toast.success("Password changed successfully");
            this.logOut();

          } else if(res.data.rows[0].error.includes("com.opentext.otds.usergroup.PasswordException:") || res.data.rows[0].error.includes("PASSWORD_TOO_WEAK:")) {
            this.setState({ passwordPolicyError:res.data.rows[0].error.toString().split(':')[1] })
          }
            else if (res.data.rows[0].error.includes("PASSWORD_IN_HISTORY")) {
            this.setState({ passwordPolicyError: "New password cannot be same as any of the previous 10 passwords" });
          }
          else if (res.data.rows[0].error.includes('INVALID_CREDENTIALS')) {
            this.setState({ invalidCred: true });

          }
          else toast.error(res.data.rows[0].error,{
            autoClose: false
          });
        })
        .catch(err => {
          if(err.toString().includes('401')){
            Axios({
              url:"/token",
              method: "GET",
              headers: {
                "x-env": DP_ENV,
                "authorization": authorizationJwt()
              },
              data:'grant_type=client_credentials'
            }).then(res => {
               res.data && cookie.save("token", res.data.access_token, {path: "/"});
               this.changePassword()
              })
              .catch(err => {
              })}else
             toast.error(err);
        });
    }
  };
  render() {
    return (
      <div>
        <div className="password tab-content">
          <div className="outerboxpass">
            <div className="passheading">
              <h5 style={{ fontWeight: "700" }}> Change Password</h5>
            </div>
            <div className="Content">
              <div className="margins">
                <Row>
                  <Row className="bottomspace">
                    <Col xs={4}>
                      <label style={{ float: "right" }}>
                        Current Password:
                      </label>{" "}
                      &nbsp;&nbsp;
                    </Col>
                    <Col xs={8}>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        name="currentPass"
                        value={this.state.currentPass}
                        onChange={e => {
                          this.handleChange(e);
                        }}
                        className="form-control entryBox"
                      />
                      <p
                        style={{
                          color: "red",
                          display:
                            this.state.invalidCred ? "" : "none"
                        }}
                      >
                        The current password provided is incorrect.
                      </p>
                    </Col>
                  </Row>
                  <Row className="bottomspace">
                    <Col xs={4}>
                      <label style={{ float: "right" }}>New Password:</label>{" "}
                      &nbsp;&nbsp;
                    </Col>
                    <Col xs={8}>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        name="newPass"
                        value={this.state.newPass}
                        onChange={e => {
                          this.handleChange1(e);
                        }}
                        className={
                          this.state.currentPass === this.state.newPass &&
                          this.state.currentPass !== ""
                            ? "form-control highlight"
                            : "form-control entryBox"
                        }
                      />
                      <p
                        style={{
                          color: "red",
                          display:
                            this.state.currentPass === this.state.newPass &&
                            this.state.currentPass !== ""
                              ? ""
                              : "none"
                        }}
                      >
                        New password cannot be the same as current password.
                      </p>
                    </Col>
                  </Row>
                  <Row className="bottomspace">
                    <Col xs={4}>
                      <label style={{ float: "right" }}>
                        Re-Enter New Password:
                      </label>{" "}
                      &nbsp;&nbsp;
                    </Col>
                    <Col xs={8}>
                      <input
                        type={this.state.showPassword ? "text" : "password"}
                        name="reEnterNewPass"
                        value={this.state.reEnterNewPass}
                        onChange={e => {
                          this.handleChange2(e);
                        }}
                        className={
                          this.state.newPass !== this.state.reEnterNewPass &&
                          this.state.newPass !== "" &&
                          this.state.reEnterNewPass !== ""
                            ? "form-control highlight"
                            : "form-control entryBox"
                        }
                      />
                      <div
                        style={{
                          color: "red",
                          display:
                            this.state.newPass !== this.state.reEnterNewPass &&
                            this.state.reEnterNewPass !== ""
                              ? ""
                              : "none"
                        }}
                      >
                        Passwords do not match
                      </div>
                    </Col>
                  </Row>
                  <Row className="bottomspace">
                    <Col xs={2}></Col>
                    <Col xs={8}>
                      <div
                        style={{
                          color: "red",
                          display: this.state.passwordPolicyError !== "" ? "" : "none"
                        }}
                      >
                        {this.state.passwordPolicyError}
                      </div>
                    </Col>
                    <Col xs={2}></Col>
                  </Row>
                  <Row className="bottomspace">
                    <Col xs={2}></Col>
                    <Col xs={5} style={{ textAlign: "left" }}>

                      <label>New Password Requirements:</label>
                      <ul style={{ fontSize: '11px' }}>
                        <li>Minimum length of 10 characters</li>
                        <li>Minimum 1 UPPER CASE alphabet</li>
                        <li>Minimum 1 lower case alphabet</li>
                        <li>Minimum 1 number</li>
                        <li>Minimum 1 symbol</li>
                        <li>Should NOT use any of the last 10 passwords</li>

                      </ul>
                    </Col>
                    <Col xs={3} style={{ textAlign: "right" }}>

                      <label>Show Passwords </label> &nbsp;

                      <Switch defaultChecked={false} onChange={e => { this.onShowpassword(e) }} />
                    </Col>
                    <Col xs={2}></Col>
                  </Row>

                  <Row>
                    <div style={{ textAlign: "left" }}>
                      NOTE: After successfully changing your password, you will
                      be logged off.
                      <Button
                        className="btn-primary"
                        style={{ float: "right" }}
                        onClick={this.changePassword}
                        disabled={
                          this.state.currentPass === "" ||
                          this.state.newPass === "" ||
                          this.state.reEnterNewPass === "" ||
                          (this.state.currentPass === this.state.newPass &&
                            this.state.currentPass !== "") ||
                          (this.state.newPass !== this.state.reEnterNewPass &&
                            this.state.newPass !== "" &&
                            this.state.reEnterNewPass !== "")
                            ? true
                            : false
                        }
                      >
                        {" "}
                        Change Password{" "}
                      </Button>
                    </div>
                  </Row>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className="password tab-content">
          <div className="outerbox">
            <div className="passheading">
              <h5 style={{ fontWeight: "700" }}>
                Message From Sysco(
                {localStorage.getItem("loginMsgDate")
                  ? moment(localStorage.getItem("loginMsgDate")).format(
                      "MM/DD/YYYY"
                    )
                  : ""}
                )
              </h5>
            </div>
            <div className="Content">
              <Row>
                <p style={{ paddingLeft: "1%", fontWeight: "600" }}>
                  {localStorage.getItem("loginMsg")
                    ? localStorage.getItem("loginMsg")
                    : ""}
                </p>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassComponent;
