import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import "./index.css";
import App from "./App";
import Amplify from "aws-amplify";
import ampConfig from "./config/amplifyConfig";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reducer from "./store/reducers/index";
import createSagaMiddleWare from "redux-saga";
import history from "./utils/history";
import searchSuppWeb from "./store/sagas/SearchSaga";
import Axios from "axios";

import { watchDashboard } from "./store/sagas/";

const sagaMiddleWare = createSagaMiddleWare();
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  reducer,
  composeEnhancer(applyMiddleware(sagaMiddleWare))
);
// ,window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
sagaMiddleWare.run(searchSuppWeb);
sagaMiddleWare.run(watchDashboard); //saga imported

Axios.get('/get-amplify-config')
  .then(res => {
    ampConfig.Auth.oauth.domain = res.data.domain;
    ampConfig.Auth.oauth.redirectSignIn = res.data.redirect;
    ampConfig.Auth.userPoolId = res.data.pool;
    ampConfig.Auth.userPoolWebClientId = res.data.client;

    Amplify.configure(ampConfig);

    ReactDOM.render(
      <Provider store={store}>
        <Router history={history}>
          <App />
        </Router>
      </Provider>,
      document.getElementById("root")
    );
  })
