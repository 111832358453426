import { combineReducers } from "redux";
import searchReducer from "../reducers/searchReducer";
import tabViewReducer from "../../common/sysco-ui-components/store/reducers/tabViewReducer";
import loadingIndicatorreducer from "../../common/sysco-ui-components/store/reducers/LoadingIndicatorReducer";

import DashboardReducer from "./getDashboardReducer";
import ResponseDetailReducer from "./responseDetailReducer";
import DocumentDetailReducer from "./documentDetailReducer";
import loginReducer from "./loginReducer";


export default combineReducers({
  tabViewReducer,
  searchReducer,
  DashboardReducer,
  loadingIndicatorreducer,
  ResponseDetailReducer,
  DocumentDetailReducer,
  loginReducer
});
