const ampConfig = {
    Auth: {
        oauth: {
            domain: '',
            scope: ['email', 'openid', 'phone'],
            redirectSignIn: '',
            redirectSignOut: '',
            responseType: 'code'
        },
        region: 'us-east-1',
        userPoolId: '',
        userPoolWebClientId: ''
    },
};

export default ampConfig;