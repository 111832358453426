export const feeClaimTypesuffixes = [
"digitalenbfee",
"photofeeext",
"photofeeint",
"suppfeebp",
"suppfeefr",
"suppfeeic",
"suppfeeie",
"suppfeeonsa",
"suppfeeotc",
"suppfeetcr",
"complianceExpenseOffset"
];

export const DOCNAME_START = "Claim Backup";

export const INVOICE_NO_START = "SMS";