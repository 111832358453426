import React from "react";
import "./styles.css";
import ReactTable from "react-table";
import { Col, Row, Glyphicon, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { getpagination } from "../../common/sysco-ui-components/services/commonServices";
import cookie from "react-cookies";
import { downloadReport } from "../../common/sysco-ui-components/services/commonServices";
import {  env } from "../../common/sysco-ui-components/config/constant";
import { API_URL } from "../../config/apiConfig";
import { Spin } from "antd";
import newRequestContainer from "../../containers/newRequestContainer";
import CheckDetailsContainer from "../../containers/checkDetailsContainer";
import DocumentView from "../documentView";
import { SyncOutlined } from "@ant-design/icons";
class supplierWeb extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customerServiceData: [],
      radioSelection: true,
      customerSerIcon: true,
      recentIcon: true,
      reportIcon: true,
      vendorDownloadIcon: true,
      customerService: this.props.customerService,
      // dailyPendingPayablesReport: this.props.dailyPendingPayablesReport,
      fromDate: "",
      toDate: ""
    };
  }

  itemHide = item => {
    if (item === "recentChecks") {
      this.setState({
        recentIcon: !this.state.recentIcon
      });
    } else if (item === "customerService") {
      this.setState({
        customerSerIcon: !this.state.customerSerIcon
      });
    } else if (item === "payableReport") {
      this.setState({
        reportIcon: !this.state.reportIcon
      });
    } else if (item === "vendorDownload") {
      this.setState({
        vendorDownloadIcon: !this.state.vendorDownloadIcon
      });
    }
  };

  issueClick = id => {
    this.props.OnAddTab(`Daily Trial Balance:${id}`,DocumentView,{"id":id});
    // history.push(`/daily-trial-balance/${id}`);
  };
  componentDidMount() {
    cookie.remove("sessionOut",{ path: '/' });
    document.getElementById("headerMain").classList.remove("loginclass");
    document.getElementById("antTab").classList.remove("loginclass");
    // this.props.getDashboard();
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      customerService: JSON.parse(JSON.stringify(newProps.customerService)),
      customerServiceData: JSON.parse(JSON.stringify(newProps.customerService))
      // dailyPendingPayablesReport: newProps.dailyPendingPayablesReport
    });
  }

  handleChange = event => {
    if (event.currentTarget.value === "showRes") {
      this.props.getCustomerServiceReport([
        localStorage.getItem("vendorNumber"),
        1
      ]);
      this.setState({
        radioSelection: true,
        customerService: JSON.parse(JSON.stringify(this.props.customerService))
      });
    } else if (event.currentTarget.value === "openReq") {
      // let data = this.state.customerService;
      // data["showResponseValue"] =
      //   this.state.customerService.showResponseValue &&
      //   this.state.customerService.showResponseValue.filter(
      //     (a, b) => a.isClosed == "0"
      //   );
      this.props.getCustomerServiceReport([
        localStorage.getItem("vendorNumber"),
        0
      ]);
      this.setState({
        radioSelection: false,
        customerService: JSON.parse(JSON.stringify(this.props.customerService))
      });
    }
  };

  handleDate = (date, name) => {
    this.setState({ [name]: date });
  };

  newReqNav = () => {
    this.props.OnAddTab("New Request",newRequestContainer, {id:"",vendorNo:""} );
  };

  checkDetailsNav = data => {

    // let docCat=(data.docCat && data.docCat.length!=0) ? data.docCat.filter(val=>val.catName=="BSCC AP Payment"):[]

    // let vendorNo=docCat.length!=0?docCat[0].catAttrs.filter(val=>val.attrName=="Vendor Number")[0].attrValues:''
    // let checkNo=docCat.length!=0?docCat[0].catAttrs.filter(val=>val.attrName=="Check ACH Number" || val.attrName=="Check/ACH Number")[0].attrValues:''
    this.props.OnAddTab(
      `Check Details:${data.checkNumber}`,CheckDetailsContainer,{docId:data.nodeId,vendorNo:data.vendorNumber,checkNo:data.checkNumber}
    );
    // history.push(`/checkdetails/${data.nodeId}/${data.vendorNumber}/${data.checkNumber!=""?data.checkNumber:'null'}`);
  };

  dateFilter = () => {
    if(this.props.customerService && this.props.customerService.showResponseValue){

    if (this.state.fromDate && this.state.toDate) {
      let oldState = JSON.parse(JSON.stringify(this.props.customerService));
      let data = [];
      oldState && oldState.showResponseValue.forEach(item => {
        if (
          moment(new Date(item.requestDate)).format("MM/DD/YYYY") >= moment(this.state.fromDate).format("MM/DD/YYYY") &&
          moment(new Date(item.requestDate)).format("MM/DD/YYYY") <=  moment(this.state.toDate).format("MM/DD/YYYY")
        ) {
          data.push(item);
        }
      });
      oldState.showResponseValue = data;
      this.setState({ customerService: oldState });
    } else if (this.state.fromDate) {
      let oldState = JSON.parse(JSON.stringify(this.props.customerService));
      let data = [];
      oldState && oldState.showResponseValue.forEach(item => {
        if (  moment(new Date(item.requestDate)).format("MM/DD/YYYY") >= moment(this.state.fromDate).format("MM/DD/YYYY")) {
          data.push(item);
        }
      });
      oldState.showResponseValue = data;
      this.setState({ customerService: oldState });
    } else if (this.state.toDate) {
      let oldState = JSON.parse(JSON.stringify(this.props.customerService));
      let data = [];
      oldState && oldState.showResponseValue.forEach(item => {
        if ( moment(new Date(item.requestDate)).format("MM/DD/YYYY")  <=  moment(this.state.toDate).format("MM/DD/YYYY")) {
          data.push(item);
        }
      });
      oldState.showResponseValue = data;
      this.setState({ customerService: oldState });
    } else {
      this.setState({
        customerService: this.state.customerServiceData
      });
    }  
  }
  };
  getRecentRows = () => {
    let recentCheck = [];
    let temp = [];
    let count = 0;
    for (let i = 0; i < this.props.recentChecks.length; i++) {
      temp.push = (
        <div className="dataContent">
          {i + 1}.{" "}
          <a  href='javascript:void(0)' onClick={this.checkDetailsNav}>
            {" "}
            Check#: {this.props.recentChecks[i].docId}
          </a>
        </div>
      );
      if (count + 1 === 5 || this.props.recentChecks.length === i + 1) {
        recentCheck.push(<div className={"abcd"}>{temp}</div>);
        temp = [];
        count = 0;
      } else {
        count += 1;
      }
    }
    return recentCheck;
  };
  downloadVendorReport = data => {
    downloadReport(
      `supplier-web/download?docId=${data.nodeId}`,
      `vendorReport_${data.label}.zip`,"GET","",API_URL
    );
  };
  render() {
    return (
      <div style={{ backgroundColor: "#b3c2cc !important" }}>
        <div className="tableContent  tabViewContent">
          <Row className="headerRow">
            <Col xs={12} style={{ float: "right", paddingRight: "0px" }}>
              <Button
                bsStyle="primary"
                style={{ float: "right" }}
                onClick={this.newReqNav}
              >
                New Request
              </Button>
            </Col>
          </Row>
          <div
            className={
              this.state.customerSerIcon === true ? "tablebox2" : "tableminus"
            }
          >
                <Spin spinning={this.props.csLoader} tip="Loading...">
            <div style={{display:"flex",justifyContent:"space-between"}}><div className="tableheading">
              {/* Customer Service ({this.state.customerService.count}) */}
              Customer Service (
              {this.state.customerService.showResponseValue
                ? this.state.customerService.showResponseValue.length
                : 0}
              )
              <Glyphicon
                style={{ float: "right", color: "grey", paddingTop: "0.2%" }}
                className={
                  this.state.customerSerIcon === true
                    ? "glyphicon-triangle-bottom"
                    : "glyphicon-triangle-left "
                }
                onClick={() => {
                  this.itemHide("customerService");
                }}
              />
            </div>
            <SyncOutlined className="refreshStyle"
              onClick={event => {
                event.stopPropagation();
                this.props.getCustomerServiceReport([localStorage.getItem("vendorNumber"),this.state.radioSelection===true?1:0]);
              }}
            /></div>
            <div>
              {" "}
              {this.state.customerSerIcon ? (
                <div>
                  {/* <label className="fonts" style={{ marginRight: "15px" }}>
                    <input type="radio" style={{ marginRight: "5px" }} />
                    Show Responses
                  </label>
                  <label className="fonts" style={{ marginRight: "15px" }}>
                    <input type="radio" style={{ marginRight: "5px" }} />
                    Open Requests
                  </label> */}
                  <div className="tableSubrow">
                    <div className="radio">
                      <label className="fonts" style={{ marginRight: "15px" }}>
                        <input
                          type="radio"
                          value="showRes"
                          checked={this.state.radioSelection}
                          style={{ marginRight: "5px" }}
                          onChange={this.handleChange}
                        />
                        Show Responses
                      </label>

                      <label className="fonts" style={{ marginRight: "15px" }}>
                        <input
                          type="radio"
                          value="openReq"
                          checked={!this.state.radioSelection}
                          style={{ marginRight: "5px" }}
                          onChange={this.handleChange}
                        />
                        Open Requests
                      </label>
                    </div>
                    <div className="subrowRightContent">
                      <div className="datepicker">
                        <p style={{ paddingRight: "6px", paddingLeft: "0" }}>
                          From Date
                        </p>
                        <DatePicker
                          name="fromDate"
                          maxDate={moment()}
                          value={this.state.fromDate}
                          selected={this.state.fromDate}
                          onChange={date => {
                            this.handleDate(date, "fromDate");
                          }}
                        />
                      </div>
                      <div className="datepicker">
                        <p style={{ paddingRight: "6px", paddingLeft: "0" }}>
                          To Date
                        </p>
                        <DatePicker
                          name="toDate"
                          minDate={this.state.fromDate}
                          value={this.state.toDate}
                          selected={this.state.toDate}
                          onChange={date => {
                            this.handleDate(date, "toDate");
                          }}
                        />
                      </div>
                      <Button
                        bsStyle="success"
                        onClick={this.dateFilter}
                        style={{
                          height: "fit-content",
                          marginTop: "2px",
                          marginBottom: "10px"
                        }}
                      >
                        Find
                      </Button>
                    </div>
                  </div>
                  <CustomerServiceTable
                    data={
                      this.state.customerService.showResponseValue && this.state.customerService.showResponseValue != ""
                        ? this.state.customerService.showResponseValue
                        : []
                    }
                    routeToRequestDetails={this.props.routeToRequestDetails}
                  />
                </div>
              ) : null}
            </div>
            </Spin>
          </div>
          <div
            className={
              this.state.recentIcon === true ? "tablebox2" : "tableminus"
            }
          > 
           <Spin spinning={this.props.recentLoader} tip="Loading...">

           <div style={{display:"flex",justifyContent:"space-between"}}> <div className="tableheading">
              Recent Checks (
              {this.props.recentChecks?this.props.recentChecks.length:0}
              )
              <Glyphicon
                style={{ float: "right", color: "grey", paddingTop: "0.2%" }}
                className={
                  this.state.recentIcon === true
                    ? "glyphicon-triangle-bottom"
                    : "glyphicon-triangle-left "
                }
                onClick={() => {
                  this.itemHide("recentChecks");
                }}
              />
            </div>
            <SyncOutlined className="refreshStyle"
              onClick={event => {
                event.stopPropagation();
                this.props.getRecentChecks(localStorage.getItem("subvendorList")) }}
            /></div>
            <div
              style={
                this.props.recentChecks.length != 0
                  ? {
                      gridAutoFlow: "column",
                      display: "grid",
                      gridTemplate: `repeat(${
                        Math.ceil(
                          this.props.recentChecks.length / 7 /* no of columns*/
                        ) < 5 /* no of rows*/
                          ? 5 /* no of rows*/
                          : Math.ceil(
                              this.props.recentChecks.length /
                                7 /* no of columns*/
                            )
                      }, 1fr) / repeat(7, 1fr)`
                    }
                  : {}
              }
            >
              {" "}
              {this.state.recentIcon ? (
                this.props.recentChecks.length ? (
                  this.props.recentChecks.map((item, i) => {
                    // let docCat=(item.docCat && item.docCat.length!=0) ? item.docCat.filter(val=>val.catName=="BSCC AP Payment"):[]
                    // let rcNo=docCat.length!=0 ? docCat[0].catAttrs.filter(val=>val.attrName=="Check ACH Number" || val.attrName=="Check/ACH Number"):""
                  // /  Check#: {rcNo.length!=0 ?rcNo[0].attrValues:""}
                    return (
                      <div className="dataContent">
                        {i + 1}.{" "}
                        <a href='javascript: void(0)' onClick={() => this.checkDetailsNav(item)}>
                          {" "}
                          Check#: {item.checkNumber}
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <div className="dataContent" style={{color:"black"}}>There are no Recent Checks available.</div>
                )
              ) : null}
            </div>
          </Spin>
          </div>
          <div
            className={
              this.state.reportIcon === true ? "tablebox2" : "tableminus"
            }
          >
              <Spin spinning={this.props.dailyLoader} tip="Loading...">
              <div style={{display:"flex",justifyContent:"space-between"}}> <div className="tableheading">
              Daily Pending Payable Report (
              {this.props.dailyPendingPayablesReport?
                this.props.dailyPendingPayablesReport.length:0}
              )
              <Glyphicon
                style={{ float: "right", color: "grey", paddingTop: "0.2%" }}
                className={
                  this.state.reportIcon === true
                    ? "glyphicon-triangle-bottom"
                    : "glyphicon-triangle-left "
                }
                onClick={() => {
                  this.itemHide("payableReport");
                }}
              />
            </div>
            <SyncOutlined className="refreshStyle"
              onClick={event => {
                event.stopPropagation();
                this.props.getPendingPayableReport(localStorage.getItem("subvendorList")) }}
            /></div>
            <div>
              {" "}
              {this.state.reportIcon ? (
                <div
                  style={
                    this.props.dailyPendingPayablesReport.length != 0
                      ? {
                          gridAutoFlow: "column",
                          display: "grid",
                          gridTemplate: `repeat(${
                            Math.ceil(
                              this.props.dailyPendingPayablesReport.length /
                                4 /* no of columns*/
                            ) < 5 /* no of rows*/
                              ? 5 /* no of rows*/
                              : Math.ceil(
                                  this.props.dailyPendingPayablesReport.length /
                                    4 /* no of columns*/
                                )
                          }, 1fr) / repeat(4, 1fr)`
                        }
                      : {}
                  }
                >
                  {this.props.dailyPendingPayablesReport.length ? (
                    this.props.dailyPendingPayablesReport.map((item, i) => {
                      // let docCat1=(item.docCat && item.docCat.length!=0) ? item.docCat.filter(val=>val.catName=="BSCC AP Reporting"):[]
                      // let dpNo=docCat1.length!=0 ? docCat1[0].catAttrs.filter(val=>val.attrName=="Vendor Number"):""
                      // dpNo.length!=0 ?dpNo[0].attrValues:""
                      return (
                        <div className="dataContent">
                          <a
                            href="javascript:void(0)"
                            onClick={() => this.issueClick(item.nodeId)}
                            // className="atag"
                          >
                            {`Pending Payables Report #${i + 1}: (${
                              item.vendorNumber
                            })`}
                          </a>
                        </div>
                      );
                    })
                  ) : (
                    <div className="dataContent" style={{color:"black"}} >There are no Pending Payables Reports available.</div>
                  )}
                </div>
              ) : null}
            </div>
            </Spin>
          </div>
          <div
            className={
              this.state.vendorDownloadIcon === true
                ? "tablebox2"
                : "tableminus"
            }
          >
              <Spin spinning={this.props.vendorLoader} tip="Loading...">
              <div style={{display:"flex",justifyContent:"space-between"}}> <div className="tableheading">
              Vendor Download Link (
              {this.props.vendorDownloadLinks?
                this.props.vendorDownloadLinks.length:0}
              )
              <Glyphicon
                style={{ float: "right", color: "grey", paddingTop: "0.2%" }}
                className={
                  this.state.vendorDownloadIcon === true
                    ? "glyphicon-triangle-bottom"
                    : "glyphicon-triangle-left "
                }
                onClick={() => {
                  this.itemHide("vendorDownload");
                }}
              />
            </div>
            <SyncOutlined className="refreshStyle"
              onClick={event => {
                event.stopPropagation();
                this.props.getVendorDownloadLinks(localStorage.getItem("vendorNumber"))}}
            /></div>
          
            <div
              style={
                this.props.vendorDownloadLinks.length != 0
                  ? {
                      gridAutoFlow: "column",
                      display: "grid",
                      gridTemplate: `repeat(${
                        Math.ceil(
                          this.props.vendorDownloadLinks.length /
                            9 /* no of columns*/
                        ) < 5 /* no of rows*/
                          ? 5 /* no of rows*/
                          : Math.ceil(
                              this.props.vendorDownloadLinks.length /
                                9 /* no of columns*/
                            )
                      }, 1fr) / repeat(9, 1fr)`
                    }
                  : {}
              }
            >
              {" "}
              {this.state.vendorDownloadIcon ? (
                this.props.vendorDownloadLinks.length ? (
                  this.props.vendorDownloadLinks.map((item, i) => {
                    // <div>{this.getRecentRows()}</div>
                    //onClick={() => this.downloadVendorReport(item)}
                    return (
                      <div className="dataContent">
                        {i + 1}.{" "}
                        <a  href={`https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${
             item.nodeId
        }/content?OTDSTicket=${cookie.load("serviceToken")}`} >
                          {" "}
                          {item.label}
                        </a>
                      </div>
                    );
                  })
                ) : (
                  <div className="dataContent" style={{color:"black"}}>There are no Vendor Documents available.</div>
                )
              ) : null}
            </div>
            </Spin>
          </div>
        </div>
      </div>
    );
  }
}
class CustomerServiceTable extends React.Component {
  render() {
    const columns = [
      {
        Header: "Request#",
        accessor: "requestNo",
        Cell: props => (
          <a
            href='javascript: void(0)'
            onClick={() => {
              this.props.routeToRequestDetails(props.value);
            }}
          >
            <u>{props.value}</u>
          </a>
        ),
        width: 100
      },
      {
        Header: "Summary Description",
        accessor: "summaryDescription"
      },
      {
        Header: "Request Date",
        accessor: "requestDate",
        width: 140
      },
      {
        Header: "Response Date",
        accessor: "responseDate",
        width: 140
      },

      {
        Header: "Is Closed",
        accessor: "isClosed",
        Cell: props => <p>{props.value == "1" ? "YES" : "NO"}</p>,
        width: 100
      }
    ];

    return (
      <div>
        <ReactTable
          className="-striped"
          style={{ outline: "none" }}
          data={this.props.data}
          columns={columns}
          defaultPageSize={5}
          pageSizeOptions={getpagination(this.props.data && this.props.data.length)}
        />
      </div>
    );
  }
}

export default supplierWeb;
