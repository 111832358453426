import React, { Component } from 'react';
import NewRequest from "../../components/newRequest/index";
import { createNewRequest } from "../../store/actions/newRequestAction";
import { connect } from "react-redux";
import history from '../../utils/history';

class NewRequestContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    submitRequest = (data) => {
        this.props.submitRequest(data);
    }
    componentDidMount(){
        !localStorage.getItem("authToken") && history.push("/")
    }
    render() {
        return (
            <div>
                <NewRequest submitRequest={this.submitRequest} tabDetails={this.props.tabDetails} data={this.props.data}
                delete={this.props.delete} keyval={this.props.keyval} />
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
     tabDetails:state.tabViewReducer
}};
const mapDispatchToProps = dispatch => {
    return {
        submitRequest: (data) => {
            dispatch(createNewRequest(data))
        }
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewRequestContainer);
