
// login page validation messages
export const INCORRECT_MESSAGE = 'Username or Password is incorrect. Please try again!';
export const UNAUTHORIZED_MESSAGE = 'You are not authorized. Please try again!'
export const NETWORK_ERROR = 'Network Error.Please Try again after some time!!'
export const INACTIVEVENDOR_MESSAGE = 'Vendor is not active.'
export const CAPTCHAFAILED_MESSAGE = 'Bot verification is failed. Please try again.'
export const CAPTCHA_VERIFICATION_REQUIRED_MESSAGE = "Captcha verification is required."


export const RESPONSE_DETAILS = [
    { key: "requestNumber", label: "Request#" },
    { key: "responder", label: "Responder" },
    { key: "requestDate", label: "Request Date" },
    { key: "ocNumber", label: "OC#" },
    { key: "poNumber", label: "PO#" },
    { key: "invoiceNumber", label: "Invoice#" },
    { key: "invoiceDate", label: "Invoice Date" },
    { key: "paymentNumber", label: "Payment#" },
    { key: "paymentDate", label: "Payment Date" },
    { key: "paymentAmount", label: "Payment Amount" },
]

export const DOC_TYPES = [
    { key: "Claims Backup", label: "Claims Backup" },
    { key: "Last Confirmed PO", label: "Last Confirmed PO(previously-Last Closed PO)"},
    { key: "Vendor Invoice", label: "Vendor Invoice(previously-Original Supplier Invoice)" },
    { key: "Check/ACH", label: "Check/ACH" },
    { key: "Backup", label: "Backup" },
    { key: "Electronic Billback", label: "Electronic Billback" },
    { key: "Expense Invoice", label: "Expense Invoice" },
];

export const LOGIN_TYPE = 'loginType'
// login types
export const LOGIN_SSS = 'SSS';
export const LOGIN_DIRECT = 'Direct'