import * as actionTypes from '../actions/actionTypes';

const initialState = {
    loginStatus: false,
    userToken: '',
    loginFailed: false,
    serviceFailed: false,
    inactiveVendor: false,
    captchaFailed: false,
    accountLocked:false,
    changeAfterReset:false,
    passwordExpired:false
}

const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return { ...state, loginStatus: true, userToken: action.payload }
        case actionTypes.AUTH_FAIL:
            localStorage.removeItem('userToken');
            if (action.payload === 'success') {
                return { ...state, loginFailed: false }
            } else {
                return { ...state, loginFailed: true };
            }
        case actionTypes.SERVICE_FAIL:
            return { ...state, serviceFailed: true }
        case actionTypes.INACTIVE_VENDOR:
            return { ...state, inactiveVendor: true }
        case actionTypes.CAPTCHA_FAILED:
            return { ...state, captchaFailed: true }
        case actionTypes.LOGIN_FAILED_ACCOUNT_LOCKED:
            if (action.payload === "failed") {
                return { ...state, accountLocked: true }
            } else {
                return { ...state, accountLocked: false }
            } 
        case actionTypes.LOGIN_FAILED_CHANGE_AFTER_RESET:
            if (action.payload === "failed") {
                return { ...state, changeAfterReset: true }
            } else {
                return { ...state, changeAfterReset: false , passwordExpired: false }
            } 
        case actionTypes.LOGIN_FAILED_PASSWORD_EXPIRED:
            if (action.payload === "failed") {
                return { ...state, passwordExpired: true }
            } else {
                return { ...state, passwordExpired: false , changeAfterReset:false }
            }
        default:
            return state;
    }

}

export default loginReducer;