import React, { Component } from "react";
import { Row, Col,Button } from "react-bootstrap";
import { RESPONSE_DETAILS } from "../../config/string";
import cookie from "react-cookies";
import "./style.css";
import Axios from "axios";
import { API_URL_SW, authorization, API_URL, DP_ENV, authorizationJwt } from "../../config/apiConfig";
import { Loader,downloadReport } from "../../common/sysco-ui-components/services/commonServices";
import { toast } from "react-toastify";
import { SyncOutlined } from "@ant-design/icons";
class ResponseDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsedetails: RESPONSE_DETAILS,
      replyMsg:'',
      resDetails:{},
      resAttachments:[]
    };
  }

sendReply=()=>{
  Loader(true)
  Axios({
    url: `${API_URL_SW}/update-existing-request`,
    method: "POST",
    headers: {
      authorization: authorization()
        },
    data:{
      "requestId":this.props.requestId,
      "replyMessage":this.state.replyMsg
    }
  })
    .then(res => {
      Loader(false)
      this.props.getDetails()
      this.setState({replyMsg:""})
      document.getElementById("replyMsgText").value="";
      toast.success("Reply sent successfully")
      this.props.deleteTab(this.props.keyval)
    })
    .catch(err => {
      if(err.toString().includes('401')){
        Axios({
          url:"/token",
          method: "GET",
          headers: {
            "x-env": DP_ENV,
            "authorization": authorizationJwt()
          },
          data:'grant_type=client_credentials'
        }).then(res => {
           res.data && cookie.save("token", res.data.access_token, {path: "/"});
           this.sendReply()
          })
          .catch(err => {
          })}
          else{
            toast.error("Failed to sent reply,Please try again.")
          }
          Loader(false)
    });
}
componentDidMount(){
  this.getResDetails();
  this.getAttachments();
}
getResDetails=()=>{
  Loader(true)
  Axios({
    url: `${API_URL_SW}/open-existing-request?requestId=${this.props.requestId}`,
    method: "GET",
    headers: {
      authorization: authorization()
        }
  })
    .then(res => {
      Loader(false)
      this.setState({resDetails:res.data.rows[0].requestDetails && res.data.rows[0].requestDetails.length!==0?res.data.rows[0].requestDetails[0]:{}})
    })
    .catch(err => {
      if(err.toString().includes('401')){
        Axios({
          url:"/token",
          method: "GET",
          headers: {
            "x-env": DP_ENV,
            "authorization": authorizationJwt()
          },
          data:'grant_type=client_credentials'
        }).then(res => {
           res.data && cookie.save("token", res.data.access_token, {path: "/"});
           this.getResDetails()
          })
          .catch(err => {
          })}
          else{
            toast.error("Failed to load Details, Please try again",{
              autoClose: false
            })
          }
          Loader(false)
    });
}

getAttachments=()=>{
  Loader(true)
  Axios({
    url: `${API_URL_SW}/get-attachments?vendorRequestID=${this.props.requestId}`,
    method: "GET",
    headers: {
      authorization: authorization()
        }
  })
    .then(res => {
      Loader(false)
       this.setState({resAttachments:res.data.rows[0].dataResult && res.data.rows[0].dataResult.length!==0?res.data.rows[0].dataResult:[]})
    })
    .catch(err => {
      if(err.toString().includes('401')){
        Axios({
          url:"/token",
          method: "GET",
          headers: {
            "x-env": DP_ENV,
            "authorization": authorizationJwt()
          },
          data:'grant_type=client_credentials'
        }).then(res => {
           res.data && cookie.save("token", res.data.access_token, {path: "/"});
           this.getAttachments()
          })
          .catch(err => {
          })}
          else{
            toast.error("Failed to load Attachments, Please try again",{
              autoClose: false
            })
          }
          Loader(false)
    });
}


downloadAttachment = (docId, name) =>{
  downloadReport(
    `supplier-web/download?docId=${docId}`,
      name + ".pdf","GET","",API_URL
  );
}
    // downloadReport(`/download?docId=${docId}`, name + ".pdf")

  postThisResponse = () => {
    this.setState({ postThisResponse: !this.state.postThisResponse })
  }

  render() {
    return (
      <div className="responseContent">
        <div className="responsebox">
         <div style={{display:"flex",justifyContent:"space-between"}}> <div className="responseheading">
            <h5>Response Details</h5>
          </div>
          <SyncOutlined className="refreshStyle"
              onClick={event => {
                event.stopPropagation();
                this.getResDetails();
                this.getAttachments();}}
            /></div>
          <div style={{ paddingTop: "1%" }}>
            <Row xs={12} className="coloring">
              {this.state.responsedetails.map(item => {
                return (
                  <Col xs={3} >
                    <span>{item.label}:</span>&nbsp;<span>{this.state.resDetails[item.key]}</span>
                  </Col>
                )
              })}

            </Row>
            <div style={{ paddingTop: "1%" }} >
              <h5>Summary</h5>
              <Row style={{ border: "2px solid #C9C3BF", paddingTop: "10px" }} >
                <p style={{ paddingLeft: "10px" }} >{this.state.resDetails.requestSummary}</p>
              </Row>
            </div>
            <div style={{ paddingTop: "1%" }} >
              <h5>Request History</h5>
              <Row style={{ border: "2px solid #C9C3BF", paddingTop: "5px", wordBreak: "break-all"
 }} >
                {/* <p style={{ paddingLeft: "10px", borderBottom: "1px solid #C9C3BF", marginRight: "20px", whiteSpace: "pre-line", paddingBottom: "5px" }} >{this.state.resDetails.requestHistory}</p> */}
              <p style={{ paddingLeft: "10px", marginRight: "20px", whiteSpace: "pre-line", paddingBottom: "5px" }} >{this.state.resDetails.requestHistory}</p>
              </Row>
            </div>
            <div style={{ paddingTop: "1%" }} >
              <h5>Attachments:</h5>
              <Row style={{ paddingTop: "10px" }} >
              
              
              {this.state.resAttachments && this.state.resAttachments.length!==0?this.state.resAttachments.map(value => {
                return (
                  <div><a onClick={() => { this.downloadAttachment(value.docId, value.docName) }} style={{ textDecoration: "underline" }}>{value.docName}</a> <br /> </div>
                )
              }):null}

              </Row>
            </div>
            <div style={{ paddingTop: "1%", marginBottom: "10px" }} >
              <h5>Reply</h5>
              <textarea  disabled={this.state.resDetails.isClosed=="1"} class="form-control" id="replyMsgText" rows="4" onInput={e=>this.setState({replyMsg:e.target.value})}/>
            </div>
            <div style={{display:"flex",justifyContent: "space-between"}}>
            <div style={{display:"flex"}}>
            <button class="btn btn-success"  disabled={!this.state.replyMsg} style={{ marginBottom: "10px", marginRight:"5px" }} onClick={this.sendReply} >Send</button>
           {this.state.resDetails.isClosed=="1" &&<h6 style={{marginTop: "10px"}}> (This request has been closed)</h6>}
            </div>
            
            <Button
                    bsStyle="primary"
                    onClick={()=> this.props.deleteTab(this.props.keyval)
                    }
                    style={{
                      marginBottom: "10px",
                      // position: 'absolute',
                      float: "right",
                      right: "10px"
                    }}
                  >
                   Close
                  </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResponseDetails;
