export const HOME = "/";
export const LOGIN_PATH = "/login";
export const LANDING = "/landing";
export const DASHBOARD = "/dashboard";
export const SEARCH = "/Search";
export const DOCDETAILS = "/DocDetails/:id/:docType";
export const CHECK_DETAILS = "/checkdetails/:docId/:vendorNo/:checkNo";
export const RESPONSE_DETAILS = "/responsedetails/:id";
export const NEW_REQUEST = "/request/:id?/:vendorNo?"; 
export const REQUESTINFO = "/requestInfo";
export const PASSWORD_CHANGE = "/change-password";
export const USERGUIDE = "/user-guide";
export const DOCUMENT_VIEW = "/daily-trial-balance/:id";
export const SEARCH_RESULTS = "/search-results/:id/:venNo/:docType/:checkNo?";

export const DOWNLOAD = "/download/:id/:name?";


