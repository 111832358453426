
const initialState = {
  spinner_status: false
};

function loadingIndicatorreducer(state, action) { 
  if (!state) {
    return initialState;
  }
  switch (action.type) {
    case "LOADING_INDICATIOR": 
      return {
        spinner_status: action.data.spinner_status
      };
    default:
      return state;
  }
}

export default loadingIndicatorreducer;
