import React from 'react';
import './styles.css';
import { Row, Col, Button, Glyphicon } from "react-bootstrap";
import { DOC_TYPES } from '../../config/string';
import ReactTable from "react-table";
import { getpagination, downloadReport } from '../../common/sysco-ui-components/services/commonServices';
import { store } from '../..';
import { addTab } from '../../common/sysco-ui-components/store/actions/tabViewAction';
import { API_URL } from '../../config/apiConfig';
import docDetailsContainer from '../../containers/docDetailsContainer';
import Policy_Document from '../policyDoc/policyDocument';
import { Tooltip } from 'antd';
import { env, ENV } from "../../common/sysco-ui-components/config/constant";
import  cookie from "react-cookies";
import history from "../../utils/history";
import { getToken } from "../../config/apiConfig";
import { feeClaimTypesuffixes,DOCNAME_START,INVOICE_NO_START } from "../../config/searchComponentConfig"
class SearchComponent extends React.Component {
    state = {
        docTypes: DOC_TYPES,
        searchCriteriaFlag: true,
        tableshow: false,
        submitFlag: true,
        checkNo: "",
        invoiceNo: "",
        orderControlNo: "",
        poNumber: "",
        checkList: [],
        tempData1: {},
        tempData2: {},
        expenseInvoiceTempData: {},
        checkListAll:false,
        showPolicylink:false,
        query1: {
            "criteria": [
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Document Type",
                    "attrValue": [],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "OpCo Global",
                    "attrName": "OpCo Number",
                    "attrValue": ["000"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Vendor Number",
                    "attrValue": localStorage.getItem("subvendorList") ? localStorage.getItem("subvendorList").split(",") : [],
                    "modifier": ["Is"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Is Secure",
                    "attrValue": ["True"],
                    "modifier": ["Is Not"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Vendor Invoice Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Order Control Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "BSCC PO Number",
                    "attrValue": [],
                    "modifier": ["Is"],
                    "attrType": "String"
                }
            ],
            "condition": "AND",
            "resultsCount": 5000,
            "templateName": "SUPP WEB BSCC AP Merchandise"
        },
        query2: {
            "criteria": [
                {
                    "catName": "BSCC AP Payment",
                    "attrName": "Document Type",
                    "attrValue": [],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "OpCo Global",
                    "attrName": "OpCo Number",
                    "attrValue": ["000"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Payment",
                    "attrName": "Vendor Number",
                    "attrValue": localStorage.getItem("subvendorList") ? localStorage.getItem("subvendorList").split(",") : [],
                    "modifier": ["Is"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Payment",
                    "attrName": "Is Secure",
                    "attrValue": ["True"],
                    "modifier": ["Is Not"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Payment",
                    "attrName": "Vendor Invoice Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Payment",
                    "attrName": "Order Control Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Payment",
                    "attrName": "Check ACH Number",
                    "attrValue": [],
                    "modifier": ["Is"],
                    "attrType": "String"
                }
            ],
            "condition": "AND",
            "resultsCount": 5000,
            "templateName": "SUPP WEB BSCC AP Payment"
        },
        query3: {
            "criteria": [
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Document Type",
                    "attrValue": ["Last Confirmed PO"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "OpCo Global",
                    "attrName": "OpCo Number",
                    "attrValue": ["000"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Vendor Number",
                    "attrValue": localStorage.getItem("subvendorList") ? localStorage.getItem("subvendorList").split(",") : [],
                    "modifier": ["Is"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Is Secure",
                    "attrValue": ["True"],
                    "modifier": ["Is Not"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Merchandise",
                    "attrName": "Vendor Invoice Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }
            ],
            "condition": "AND",
            "resultsCount": 5000,
            "templateName": "SUPP WEB BSCC AP Merchandise"
        },
        query4: {
            "criteria": [
                {
                    "catName": "BSCC AP Expense",
                    "attrName": "Document Type",
                    "attrValue": ["Expense Invoice"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "OpCo Global",
                    "attrName": "OpCo Number",
                    "attrValue": ["000"],
                    "modifier": ["Is"],
                    "attrType": "String"
                }, {
                    "catName": "BSCC AP Expense",
                    "attrName": "Vendor Number",
                    "attrValue": localStorage.getItem("subvendorList") ? localStorage.getItem("subvendorList").split(",") : [],
                    "modifier": ["Is"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Expense",
                    "attrName": "Is Secure",
                    "attrValue": ["True"],
                    "modifier": ["Is Not"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Expense",
                    "attrName": "Vendor Invoice Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }, 
                {
                    "catName": "BSCC AP Expense",
                    "attrName": "Order Control Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                },
                {
                    "catName": "BSCC AP Expense",
                    "attrName": "BSCC PO Number",
                    "attrValue": [],
                    "modifier": ["None"],
                    "attrType": "String"
                }
            ],
            "condition": "AND",
            "resultsCount": 5000,
            "templateName": "SUPP WEB BSCC AP Expense"
        }
    }

    componentDidMount() {
        if (this.props.searchList.length !== 0) {
            this.setState({ searchCriteriaFlag: false, submitFlag: true });
        }
    }

    handleSearch = (item) => {
        // hide the policy Docu link
        if(this.state.invoiceNo.length>0 && this.state.checkList.length> 0 ){
            this.setState({showPolicylink : this.state.invoiceNo.startsWith('SMS') && this.state.checkList.includes('Claims Backup') ? true : false})
        }else{
            this.setState({showPolicylink :false})
        }
        this.setState({ tableshow: true })
        this.state.tempData1 = Object.assign({}, this.state.query1)
        this.state.tempData2 = Object.assign({}, this.state.query2)
        this.state.expenseInvoiceTempData = Object.assign({}, this.state.query4)

        this.state.tempData1.criteria[0].attrValue = this.state.tempData2.criteria[0].attrValue = JSON.parse(JSON.stringify(this.state.checkList));
        this.state.tempData1.criteria[4].attrValue = this.state.tempData2.criteria[4].attrValue = this.state.expenseInvoiceTempData.criteria[4].attrValue = this.state.invoiceNo ? [this.state.invoiceNo] : [" "];
        this.state.tempData1.criteria[5].attrValue = this.state.tempData2.criteria[5].attrValue = this.state.expenseInvoiceTempData.criteria[5].attrValue = this.state.orderControlNo ? [this.state.orderControlNo] : [];
        this.state.tempData1.criteria[6].attrValue = this.state.expenseInvoiceTempData.criteria[6].attrValue = this.state.poNumber ? [this.state.poNumber] : [];
        this.state.tempData2.criteria[6].attrValue = this.state.checkNo ? [this.state.checkNo] : [];
        var index = this.state.tempData1.criteria[0].attrValue.findIndex(item => item === "Vendor Invoice")
        if (index > -1) {
            this.state.tempData1.criteria[0].attrValue.splice(index, 1, ...["Vendor Invoice", "EDI Vendor Invoice", "EDI Vendor Invoice - Auto", "EDI Vendor Invoice - Non Auto", "Vendor Invoice - Produce"])
        }

        if (item === "myWork") {
            this.setState({ searchCriteriaFlag: false, submitFlag: true });
        }
        if (this.state.checkNo && !this.state.invoiceNo && !this.state.orderControlNo && !this.state.poNumber) {
            let data = this.state.tempData2.criteria.filter(val => val.attrName !== "Vendor Invoice Number")
            this.state.tempData2.criteria = data
        }
        let expenseInvoiceData = this.state.checkList.includes("Expense Invoice") ? this.state.expenseInvoiceTempData : null;                     
        this.state.checkList.includes("Last Confirmed PO") ? this.props.handleSearch([this.state.tempData1, this.state.tempData2, expenseInvoiceData, "search", this.state.query3]) :
            this.props.handleSearch([this.state.tempData1, this.state.tempData2, expenseInvoiceData, "search"]);
                
    }

    handleCheckBox = (e) => {
        if (!this.state.checkList.includes(e.target.value)) {
            this.setState({ checkList: [...this.state.checkList, e.target.value] })
        }
        else {
            let double = this.state.checkList.filter(el => el !== e.target.value);
            this.setState({ checkList: double })
        }
    }

    handleCheckBoxAll=(e)=>{
        this.setState({checkList:[]});
        let data=[]
         this.setState({checkListAll:!this.state.checkListAll},()=>{
           if(this.state.checkListAll){
             this.state.docTypes.map(val=>{
               data.push(val.key)
             })
             this.setState({checkList:data})
           }else
           this.setState({checkList:[]})
         })
      }

    showDocDetails = (docName) => {
        this.props.showDocDetails(docName);
    }
    ResultHide = () => {
        this.setState({ searchCriteriaFlag: false });
    };
    CriteriaHide = item => {
        if (item === "myWork") {
            this.setState({
                searchCriteriaFlag: true
            });
        }
    };

    reset=()=>{
    this.setState({checkNo:'',invoiceNo:'',orderControlNo:'',poNumber:'',checkListAll:false,checkList:[]})    
    store.dispatch({ type: "SEARCH_DATA", data: [] });
    }
    render() {
        return (
            <div>
                <div className="tablecontent tabViewContent" >
                    <div className="tableBox">
                        <Row className="mainrows">
                            <div
                                style={{
                                    display: this.state.searchCriteriaFlag ? "" : "none"
                                }}
                            >
                                <Col xs={12} className="topBox">
                                    <Col xs={3}>
                                        <div>Check#</div>
                                        <input value={this.state.checkNo} className="form-control" name="checkNo"
                                            onChange={e => this.setState({ checkNo: e.target.value })} />

                                    </Col>
                                    <Col xs={3}>
                                        <div>Invoice#</div>
                                        <input value={this.state.invoiceNo} className="form-control" name="invoiceNo"
                                            onChange={e => this.setState({ invoiceNo: e.target.value })} />
                                    </Col>
                                    <Col xs={3}>
                                        <div>Order Control#</div>
                                        <input value={this.state.orderControlNo} className="form-control" name="OrderControlNo"
                                            onChange={e => this.setState({ orderControlNo: e.target.value })} />
                                    </Col>
                                    <Col xs={3}>
                                        <div>PO#</div>
                                        <input value={this.state.poNumber} className="form-control" name="poNumber"
                                            onChange={e => this.setState({ poNumber: e.target.value })} />
                                    </Col>
                                </Col>
                                <Col xs={12} >
                                    <label className="secondBox">
                                        Document Types
                            </label>
                                </Col>
                                <Col xs={12}>
                                    <Col xs={8}>
                                    <Col xs={6}  style={{ padding: 0 }}>
                                        <input type="checkbox" checked={this.state.checkListAll} onChange={(e) => { this.handleCheckBoxAll(e) }} value={""} style={{ float: "left" }}/>
                                        <p style={{ paddingLeft: "5px" }}>&nbsp;&nbsp; Select All</p>
                                    </Col>
                                        {this.state.docTypes.map((value, i) => {
                                            return (
                                                <div>
                                                    <Col xs={6} style={{ padding: 0 }}>
                                                        <input type="checkbox" checked={this.state.checkList.includes(value.key)} style={{ float: "left" }} value={value.key} onChange={(e) => { this.handleCheckBox(e) }} />
                                                        <p style={{ paddingLeft: "5px" }}>&nbsp;&nbsp;{value.label}</p>
                                                    </Col>
                                                </div>
                                            )
                                        })}
                                    </Col>
                                </Col>
                                <Col xs={12} >
                                    <div style={{ float: "right", paddingRight: "1.5%", paddingBottom: "1.5%" }}>
                                        <Button className="btn-primary" onClick={() => this.reset()}>Reset</Button> &nbsp;&nbsp;
                                        <Button className="btn-success" 
                                        disabled={(!this.state.checkNo && !this.state.invoiceNo && !this.state.orderControlNo && !this.state.poNumber) || 
                                        (this.state.checkList.length === 0)} 
                                        onClick={() => { this.handleSearch("myWork") }} >Search</Button>
                                    </div>
                                </Col>
                            </div>
                            <div
                                style={{
                                    display: this.state.searchCriteriaFlag ? "none" : ""
                                }}>

                                <Col xs={12} style={{ padding: "0" }}>
                                    <Col xs={10} style={{ paddingLeft: "0" }} onClick={() => {
                                        this.CriteriaHide("myWork");
                                    }}>
                                        <h4 className="closeTable">Search Criteria </h4>{" "}
                                    </Col>
                                    <Col xs={2} style={{ marginTop: "9px", paddingRight: "0" }}>
                                        <Glyphicon
                                            style={{
                                                float: "right",
                                                color: "grey",
                                                paddingTop: "0.5%"
                                            }}
                                            className={"glyphicon-triangle-left"}
                                            onClick={() => {
                                                this.CriteriaHide("myWork");
                                            }}
                                        />
                                    </Col>
                                </Col>
                            </div>

                        </Row>
                    </div>
                    <div className="filter-list">
                        <SearchTable
                            data={this.props.searchList}
                            docTypes={this.state.docTypes}
                            searchCriteriaFlag={this.state.searchCriteriaFlag}
                            ResultHide={this.ResultHide}
                            submitFlag={this.state.submitFlag}
                            showDocDetails={this.showDocDetails}
                            show={this.state.tableshow}
                            invoiceNo = {this.state.invoiceNo}
                            checkList={this.state.checkList}
                            showPolicylink = {this.state.showPolicylink}
                        />
                    </div>
                </div>
            </div>
        )
    }
}
class SearchTable extends React.Component {
    state = {
        resultCheckList: [],
        tableDisplayData: [],
        tableRawData: [],
        selectAll: false,
        zipPayload: [],
        showPolicylink: false
    }

    componentWillReceiveProps(newProps) {
        this.setState({ tableRawData: newProps.data }, () => {
            (this.state.tableRawData && this.state.tableRawData.length !== 0) ? this.generateData() : this.setState({ tableDisplayData: [] });
            this.setState({ resultCheckList: [], zipPayload: [] })
        })


        let showPolicy = newProps.data.some(doc=>{
            let SMSinitial = this.props.invoiceNo.startsWith(INVOICE_NO_START);
            let startsWithClaimBackup = doc.docName.startsWith(DOCNAME_START);
            let regex = new RegExp(`(${feeClaimTypesuffixes.join('|')})$`);
            let endsWithAny = regex.test(doc.docName);
            return SMSinitial || (startsWithClaimBackup && endsWithAny);
        })

        this.setState({showPolicylink:showPolicy} )

        


    }

    generateData = () => {
        let tableData1 = []
        this.state.tableRawData && this.state.tableRawData.map(val => {
            var category = {}
            val.docCat[0].catAttrs.map(value => {
                // tabledata.push()
                var data = value.attrName.replace(/ /g, "")
                if (data === "CheckNumber" && value.attrValues === "") {
                } else
                    Object.assign(category, { [data]: value.attrValues })
            })
            Object.assign(category, { nodeId: val.docId })
            Object.assign(category, { docName: val.docName })
            tableData1.push(category)
        })
        this.setState({ tableDisplayData: tableData1 }, () => {

        })
    }
    ResultHide = item => {
        if (item === "myResult") {
            this.props.ResultHide();
        }
    };
    checkBoxClicked = (e, i) => {
        if (!this.state.resultCheckList.includes(e.nodeId)) {
            this.setState({ resultCheckList: [...this.state.resultCheckList, e.nodeId] })
            this.onGeneratedRow(e, "add")
        }
        else {
            let double = this.state.resultCheckList.filter(el => el !== e.nodeId);
            this.setState({ resultCheckList: double })
            this.onGeneratedRow(e, "remove")
        }
    }

    onGeneratedRow(columnsResult, type) {
        var jsonData = {};
        var name = "";
        if (columnsResult.DocumentType === "Check/ACH") {
            name = `${columnsResult.DocumentType.toString().replace(/\//g, "_")}_[${columnsResult.VendorNumber}-${columnsResult.CheckNumber}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        } else if (columnsResult.DocumentType === "Last Confirmed PO") {
            name = `${columnsResult.DocumentType}_[${columnsResult.VendorNumber}-${columnsResult.PONumber}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        } else {
            name = `${columnsResult.DocumentType ? columnsResult.DocumentType.toString().replace(/\//g, "_") : ""}_[${columnsResult.VendorNumber}-${columnsResult.InvoiceNumber && columnsResult.InvoiceNumber.length !== 0 ? columnsResult.InvoiceNumber[0] : ""}]_${columnsResult.CaptureDate}_${columnsResult.nodeId}`
        }
        jsonData["dataId"] = columnsResult.nodeId.toString();
        jsonData["name"] = name;
        jsonData["docName"] = columnsResult.docName;
        this.state.zipPayload = this.state.zipPayload.filter(item => {
            return item.dataId !== columnsResult.nodeId.toString();
        });
        type === "add" && this.state.zipPayload.push(jsonData);
        this.setState({ zipPayload: this.state.zipPayload });
    }
    showDetails = (data) => {
        // this.props.showDocDetails(data.docName);
        store.dispatch(addTab("Document Details : "+data.nodeId, docDetailsContainer,{id:data.nodeId,docType:data.DocumentType[0].replace("/", "-")}));
    }
    showPolicyDoc = (urlType) => {
        // this.props.showDocDetails(data.docName);
        !localStorage.getItem("authToken") && history.push("/")
        let data = getToken()
        if(urlType === 'PhotoFee'){
            
           store.dispatch(addTab(" Photo Fee Policy Document", Policy_Document,{id:'photoFee',urlLink: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${ENV()==='-dev'?"28222407":ENV()==='-stg'?'30358407':ENV()==='-prod'?'312726604':''}/content?OTDSTicket=${cookie.load("serviceToken")}`}));

        }else if(urlType === "DigitalEnablementFee")
        {
            store.dispatch(addTab(" Digital Enablement Fee policy Document", Policy_Document,{id:'digitalEnablementFee',urlLink: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${ENV()==='-dev'?"28580097":ENV()==='-stg'?'30762287':ENV()==='-prod'?'395736614':''}/content?OTDSTicket=${cookie.load("serviceToken")}`}));

        }else if (urlType === "complianceExpenseOffset") {
            store.dispatch(addTab(" Supplier Compliance Expense Offset Policy Document", Policy_Document, { id: 'suppfeeceo', urlLink: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${ENV() === '-dev' ? "29803372" : ENV() === '-stg' ? '31844044' : ENV() === '-prod' ? '535199313' : ''}/content?OTDSTicket=${cookie.load("serviceToken")}` }));
        }
        else{
            store.dispatch(addTab("Supplier Fee Policy Document", Policy_Document,{id:'supplierFee',urlLink: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${ENV()==='-dev'?"27522250":ENV()==='-stg'?'27550542':ENV()==='-prod'?'215822629':''}/content?OTDSTicket=${cookie.load("serviceToken")}`}));
        }
        
    }
    handleAll = (e) => {
        if (this.state.resultCheckList.length === this.state.tableDisplayData.length) {
            this.setState({ resultCheckList: [], selectAll: false, zipPayload: [] })
        }
        else {
            let newList = []
            this.state.tableDisplayData.map((item, i) => {
                newList.push(item.nodeId)
                this.onGeneratedRow(item, "add")
            })
            this.setState({ resultCheckList: newList, selectAll: true })
        }

    }


    zipFile = () => {
        this.setState({ selectAll: false })
        downloadReport('supplier-web/zip-files', 'search-results.zip', "POST", this.state.zipPayload, API_URL)
    }
    render() {
        const columns = [
            {
                Header: (
                    <input
                        type="checkbox"
                        onChange={this.handleAll}
                        checked={this.state.selectAll}
                    />),
                Cell: row => {
                    let data = row.original;
                    return (
                        <div className="custom-table" style={{ textAlign: "center" }}>
                            <input type="checkbox"
                                className="checkbox"
                                id="checkbox"
                                checked={this.state.resultCheckList.includes(data.nodeId)}
                                style={{ float: "center" }}
                                value={data}
                                onChange={() => { this.checkBoxClicked(data, row.index) }} />
                        </div>
                    );
                },
                width: 50,
            },
            {

                accessor: "DocumentType",
                Header: "Document Type",

                Cell: row => {
                    return (

                        <div>

                            <a style={{ textDecoration: "underLine" }}
                                onClick={() => { this.showDetails(row.original) }}
                            >
                                <div>{row.original.DocumentType}</div>
                            </a>

                        </div>
                    );
                }

            },
            {
                Header: "Document Name",
                accessor: "docName",
                width: 250,
                Cell: val =>
                    (<div className='showfullString'>
                        <Tooltip placement="bottom" title={val.value}>
                        {val.value}
                        </Tooltip>
                    </div>)
            },
            {

                accessor: "InvoiceNumber",
                Header: "Vendor Invoice #",
                Cell: row => {
                    return (
                        <span >{(row.value && row.value.length !== 0) ? row.value.toString() : ''}</span>
                    )
                }
            },
            {
                Header: "PO #",
                accessor: "PONumber"
            },
            {
                Header: "OC #",
                accessor: "OrderControlNumber",
                Cell: row => {
                    return (

                        <div>
                            <div>{row.original.OrderControlNumber ? row.original.OrderControlNumber.toString() : ''}</div>

                        </div>
                    )
                }
            },
            {
                Header: "Voucher #",
                accessor: "VoucherNumber"
            },
            {
                Header: "Check/ACH #",
                accessor: "CheckNumber"
            },
            {
                Header: "Payment Date",
                accessor: "PaymentDate"
            },
            {
                Header: "Payment Amount",
                accessor: "PaymentAmount"
            }

        ];

        return (
            <div>
                {this.props.submitFlag ? (
                    <div className="resultTable">
                        <div style={{ textAlign: "left" }}>
                            <h4 className="resultTab">Search Result</h4>

                            {this.props.data && this.props.data.length !== 0 ? (

                                <div className="upperBodyTable2">
                                    <div className='right-to-left-buttons'>
                                        <Button bsStyle="primary"
                                            disabled={this.state.resultCheckList.length === 0}
                                            onClick={() => this.zipFile()}
                                            className="download-button"
                                        >
                                            Download Selected Document
                                        </Button>
                                        <Button bsStyle="primary" onClick={() => { this.showPolicyDoc("PhotoFee"); }} style={{
                                            display: this.state.showPolicylink ? 'inline' : 'none'
                                        }}
                                        >
                                            View Sysco Photo Fee Policy
                                        </Button>
                                        <Button bsStyle="primary" onClick={() => { this.showPolicyDoc("DigitalEnablementFee"); }} style={{
                                            display: this.state.showPolicylink ? 'inline' : 'none'
                                        }}
                                        >
                                            View Sysco Digital Enablement Fee Policy
                                        </Button>
                                        <Button bsStyle="primary" onClick={() => { this.showPolicyDoc("SupplierPerformanceFee"); }} style={{
                                            display: this.state.showPolicylink ? 'inline' : 'none'
                                        }}
                                        >
                                            View Sysco Supplier Performance Fee Policy
                                        </Button>
                                        <Button bsStyle="primary" onClick={() => { this.showPolicyDoc("complianceExpenseOffset"); }} style={{
                                            display: this.state.showPolicylink ? 'inline' : 'none'
                                        }}
                                        >
                                            View Sysco Supplier Compliance Expense Offset Policy
                                        </Button>
                                    </div>

                                    <ReactTable
                                        // resizable={false}
                                        className="-striped"
                                        defaultSorted={[
                                            {
                                                id: "DocumentType",
                                                desc: false
                                            }
                                        ]}
                                        data={this.state.tableDisplayData.length !== 0 ? this.state.tableDisplayData : []}
                                        columns={columns}
                                        defaultPageSize={5}
                                        pageSizeOptions={getpagination(this.state.tableDisplayData && this.state.tableDisplayData.length)}
                                    />
                                </div>
                            ) : (<React.Fragment>
                                <Col
                                    xs={2}
                                    style={{
                                        marginTop: "9px",
                                        paddingRight: "0",
                                        float: "right"
                                    }}
                                >
                                    <Glyphicon
                                        style={{
                                            paddingRight: "10px",
                                            float: "right",
                                            color: "grey",
                                            paddingTop: "0.5%"
                                        }}
                                        className="glyphicon-triangle-left"
                                        onClick={() => {
                                            this.ResultHide("myResult");
                                        }}
                                    />
                                </Col>
                                {this.props.show && <ReactTable
                                    className="-striped"
                                    data={[]}
                                    columns={columns}
                                    defaultPageSize={0}
                                    pageSizeOptions={[0]}
                                />}
                            </React.Fragment>
                                )}
                        </div>
                    </div>
                ) : null}

            </div>
        );
    }
}
export default SearchComponent;
