import React, { Component } from "react";
import SupplierWeb from "../../components/supplierWeb/index";
import * as actions from "../../common/sysco-ui-components/store/actions/tabViewAction";
import {
  dashboard,
  getRecentChecks,
  getPendingPayableReport,
  getCustomerServiceReport,
  login,
  getVendorDownloadLinks
} from "../../store/actions/dashboardAction";
import { connect } from "react-redux";

import history from "../../utils/history";
import { API_URL_SW, authorization, DP_ENV, authorizationJwt } from "../../config/apiConfig";
import Axios from "axios";
import  cookie from "react-cookies";
import { store } from "../..";
import { LOGIN } from "../../store/actions/actionTypes";
import responseDetailsContainer from "../responseDetailsContainer";
class SupplierWebDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerService: [],
      vendorDownloadLinks: [],
      dailyPendingPayablesReport: [],
      vendorList:
        localStorage.getItem("subvendorList") &&
        localStorage.getItem("subvendorList").split(",")
    };
  }
  componentDidMount() {
    if(localStorage.getItem("authToken")){
    this.getSubvendorList()
    store.dispatch({type:LOGIN})
    document.getElementById('headerUserName').innerHTML="Welcome    " +cookie.load('userName')
    this.props.dashboardReducer.vendorDownloadLinks.length===0 && this.props.getVendorDownloadLinks(localStorage.getItem("vendorNumber"));
    this.props.getCustomerServiceReport([localStorage.getItem("vendorNumber"),1]);
    }else
      history.push("/")

  }

  getSubvendorList = () => {
    let subvendorList = [];
    Axios({
      url: `${API_URL_SW}/get-vendornumbers-subvendors?vendorNumber=${localStorage.getItem(
        "vendorNumber"
      )}`,
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        authorization: authorization(),
      }
    })
      .then(res => {
        if (res.data.rows[0].myRows && res.data.rows[0].myRows.length !== 0) {
          subvendorList = res.data.rows && res.data.rows[0].myRows && res.data.rows[0].myRows.length!==0 && res.data.rows[0].myRows.map(val => val.vendorNumber);
        }
        localStorage.setItem("subvendorList", subvendorList);
        this.props.dashboardReducer.recentChecks.length===0 && this.props.getRecentChecks(subvendorList);
        this.props.dashboardReducer.pendingPayableReports.length===0 &&  this.props.getPendingPayableReport(subvendorList);
        // this.setState({ helpData: res.data.rows[0] && res.data.rows[0].helpList })
      })
      .catch(err => {
        if(err.toString().includes('401')){
          Axios({
            url:"/token",
            method: "GET",
            headers: {
              "x-env": DP_ENV,
              "authorization": authorizationJwt()
            },
            data:'grant_type=client_credentials'
          }).then(res => {
             res.data && cookie.save("token", res.data.access_token, {path: "/"});
             this.getSubvendorList()
            })
            .catch(err => {
            })
          }
      });
  };
  componentWillReceiveProps(newProps) {
    // this.setState({
      // customerService: newProps.dashboardReducer.dashboardValue.customerService,
      // vendorDownloadLinks: newProps.dashboardReducer.dashboardValue.vendorDownloadLinks,
      // dailyPendingPayablesReport:
      //   newProps.dashboardReducer.dashboardValue.dailyPendingPayablesReport
    // });
  }
  routeToRequestDetails = id => {
    // this.props.routeToRequestDetails(id);
    this.props.OnAddTab(`Response #:${id}`,responseDetailsContainer, {"id":id});
    // history.push(`/responsedetails/${id}`);
  };

  getToken = () => {
    this.props.login();
  };
  render() {
    return (
      <div>
        <SupplierWeb
          recentLoader={this.props.dashboardReducer.recentLoader}
          dailyLoader={this.props.dashboardReducer.dailyLoader}
          csLoader={this.props.dashboardReducer.csLoader}
          vendorLoader={this.props.dashboardReducer.vendorLoader}
          customerService={this.props.dashboardReducer.customerServieReport}
          getCustomerServiceReport={this.props.getCustomerServiceReport}
          getRecentChecks={this.props.getRecentChecks}
          recentChecks={this.props.dashboardReducer.recentChecks}
          getPendingPayableReport={this.props.getPendingPayableReport}
          getVendorDownloadLinks={this.props.getVendorDownloadLinks}
          dailyPendingPayablesReport={
            this.props.dashboardReducer.pendingPayableReports
          }
          OnAddTab={this.props.OnAddTab}
          routeToRequestDetails={this.routeToRequestDetails}
          vendorDownloadLinks={this.props.dashboardReducer.vendorDownloadLinks}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    dashboardReducer: state.DashboardReducer
  };
}
const mapDispatchToProps = dispatch => {
  return {
    OnAddTab: (title, eventKey, name, type, id, viewParam) => {
      dispatch(actions.addTab(title, eventKey, name, type, id, viewParam));
    },
    getDashboard: () => {
      dispatch(dashboard());
    },
    login: () => {
      dispatch(login());
    },
    getRecentChecks: data => {
      dispatch(getRecentChecks(data));
    },
    getPendingPayableReport: data => {
      dispatch(getPendingPayableReport(data));
    },
    getCustomerServiceReport: vendorNo => {
      dispatch(getCustomerServiceReport(vendorNo));
    },
    getVendorDownloadLinks: vendorNo => {
      dispatch(getVendorDownloadLinks(vendorNo));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupplierWebDashboard);
