// import Freight from '../../components/Freight/index';
import React from "react";
import { connect } from "react-redux";
import DocDetails from "../../components/docDetails";
import { getDocumentDetail } from "../../store/actions/documentDetailAction";
class DocDetailsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {docDetail:{}};
  }
  componentDidMount() {
    this.setState({docDetail:this.props.data})
    // this.props.getDocumentDetails(data.id, data.docType);
  }
  render() {
    return (
      <div>
        <DocDetails
          SearchData={this.props.searchReducer}
          urlParams={this.state.docDetail}
          docDetails={this.props.docDetailReducer.docDetails}
        />
        {/* :null} */}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    searchReducer: state.searchReducer,
    docDetailReducer: state.DocumentDetailReducer
  };
}
const mapDispatchToProps = dispatch => {
  return {
    getDocumentDetails: (id, catname) => {
      dispatch(getDocumentDetail(id, catname));
    }
  };
};
// const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocDetailsContainer);
//
