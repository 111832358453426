export const DASHBOARD = "DASHBOARD";
export const DASHBOARD_DATA = "DASHBOARD_DATA";

export const LOGIN = "LOGIN";
export const AUTH_LOGIN = "AUTH_LOGIN";
export const PORTAL_MESSAGE = "PORTAL_MESSAGE";


export const SERVICE_FAIL = "SERVICE_FAIL";
export const INACTIVE_VENDOR = "INACTIVE_VENDOR";
export const CAPTCHA_FAILED = "CAPTCHA_FAILED";
export const LOGIN_FAILED_PASSWORD_EXPIRED = "LOGIN_FAILED_PASSWORD_EXPIRED";
export const LOGIN_FAILED_CHANGE_AFTER_RESET = "LOGIN_FAILED_CHANGE_AFTER_RESET";
export const LOGIN_FAILED_ACCOUNT_LOCKED = "LOGIN_FAILED_ACCOUNT_LOCKED";

export const REQUEST = "REQUEST";
export const REQUEST_DATA = "REQUEST_DATA";
export const LOGIN_MSG = "LOGIN_MSG";

export const AUTH = "AUTH";
export const AUTH_START = "AUTH_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

export const RECENT_LOADER = "RECENT_LOADER";
export const DAILY_LOADER = "DAILY_LOADER";
export const CS_LOADER = "CS_LOADER";
export const VENDOR_LOADER = "VENDOR_LOADER";


export const SEARCH_ACTION = "SEARCH_ACTION";
export const SEARCH_DATA = "SEARCH_DATA";

export const SEARCH_RESULT = "SEARCH_RESULT";
export const SEARCH_RESULT_DATA = "SEARCH_RESULT_DATA";
export const SEARCH_LOCATE_DATA = "SEARCH_LOCATE_DATA";

export const RESPONSE_DETAIL = "RESPONSE_DETAIL";
export const RESPONSE_DETAIL_DATA = "RESPONSE_DETAIL_DATA";
//dashboard
export const GET_RECENT_CHECKS = "GET_RECENT_CHECKS";
export const SET_RECENT_CHECKS = "SET_RECENT_CHECKS";
export const GET_PENDING_PAYABLE_CHECK = "GET_PENDING_PAYABLE_CHECK";
export const SET_PENDING_PAYABLE_CHECK = "SET_PENDING_PAYABLE_CHECK";
export const GET_CUSTOMER_SERVICE = "GET_CUSTOMER_SERVICE";
export const SET_CUSTOMER_SERVICE = "SET_CUSTOMER_SERVICE";
export const GET_VENDOR_DOWNLOAD_LINK = "GET_VENDOR_DOWNLOAD_LINK";
export const VENDOR_DOWNLOAD_LINK_SUCCESS = "VENDOR_DOWNLOAD_LINK_SUCCESS";
//newREquest
export const CREATE_NEW_REQUEST = "CREATE_NEW_REQUEST";
export const NEW_REQUEST_SUCCESS = "NEW_REQUEST_SUCCESS";
export const GET_REQUEST_ID = "GET_REQUEST_ID";
export const UPLOAD_DATA = "UPLOAD_DATA";

//response detail
export const GET_RESPONSE_DETAIL = "GET_RESPONSE_DETAIL";
export const RESPONSE_DETAIL_SUCCESS = "RESPONSE_DETAIL_SUCCESS";
export const GET_ATTACHMENTS = "GET_ATTACHMENTS";
export const REQUEST_ATTACHMENT_DATA = "REQUEST_ATTACHMENT_DATA";
//Document details
export const GET_DOCUMENT_DETAIL = "GET_DOCUMENT_DETAIL";
export const DOCUMENT_DETAIL_SUCCESS = "DOCUMENT_DETAIL_SUCCESS";
