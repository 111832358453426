import { SEARCH_DATA, SEARCH_RESULT_DATA, SEARCH_LOCATE_DATA } from "../actions/actionTypes";

const InitialState = {
  SearchList: [],
  SearchData: [],
  SearchLocateData: []
};

const searchReducer = (state = InitialState, action) => {
  switch (action.type) {
    case SEARCH_DATA:
      return { ...state, SearchList: action.data };

    case SEARCH_RESULT_DATA:
      return { ...state, SearchData: action.data };
    case SEARCH_LOCATE_DATA:
      return { ...state, SearchLocateData: action.data };
    default:
      return state;
  }
};
export default searchReducer;
