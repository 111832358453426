import React, { Component } from "react";
import { PDFObject } from "react-pdfobject";
import { env } from "../../common/sysco-ui-components/config/constant";
import  cookie from "react-cookies";
import { getToken } from "../../config/apiConfig";

class DocumentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${
        this.props.data.id
        }/content?OTDSTicket=${cookie.load("serviceToken")}`
    };
  }

  async componentDidMount(){
    let data = await getToken()
    this.setState({
      url: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${this.props.data.id}/content?OTDSTicket=${data?data:cookie.load("serviceToken")}`
    })
  }
  render() {
    return <PDFObject  containerId={"doc-view"+this.props.data.id.toString()}  height="91Vh"  url={this.state.url} />;
  }
}

export default DocumentView;
