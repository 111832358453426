import React, { Component } from "react";
import "./style.css";
import { DOC_TYPES } from "../../config/string";

import { Row, Col, Button } from "react-bootstrap";
import { PDFObject } from "react-pdfobject";
import { env } from "../../common/sysco-ui-components/config/constant";
import cookie from "react-cookies";
import { store } from "../..";
import { addTab } from "../../common/sysco-ui-components/store/actions/tabViewAction";
import { getToken } from "../../config/apiConfig";
import newRequestContainer from "../../containers/newRequestContainer";
import SearchResultsComponent from "../../components/searchResults";

class CheckDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkList: [],
      locatedocuments: DOC_TYPES,
      checkListAll:false,
      pdfdocument: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${
        this.props.docId
        }/content?OTDSTicket=${cookie.load("serviceToken")}`
    }
  };

  async componentDidMount(){
    let data = await getToken()
    this.setState({
      pdfdocument: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${this.props.docId}/content?OTDSTicket=${data?data:cookie.load("serviceToken")}`
    })
  }
  requestNavigate = () => {
    store.dispatch(addTab(`Request:${this.props.checkNo}`,newRequestContainer, {id:this.props.docId,vendorNo:this.props.vendorNo}));
    // history.push(`/request/${this.props.docId}/${this.props.vendorNo}`);
  }

  locateNavigate = () => {
    store.dispatch(addTab(`Search Results:${this.props.checkNo}`,SearchResultsComponent,{id:this.props.docId,venNo:this.props.vendorNo,docType:this.state.checkList.toString().replace('/','-'),checkNo:this.props.checkNo}));
    // history.push(`/search-results/${this.props.docId}/${this.props.vendorNo}/${this.state.checkList.toString().replace('/','-')}/${this.props.checkNo}`);
    // if(this.state.checkList.toString().replace('/','-').includes("Check")){
    //   store.dispatch(addTab('Search Results', `search-results/${this.props.docId}/${this.props.vendorNo}/${this.state.checkList.toString().replace('/','-')}/${this.props.checkNo}`));
    //    history.push(`/search-results/${this.props.docId}/${this.props.vendorNo}/${this.state.checkList.toString().replace('/','-')}/${this.props.checkNo}`);
    // }else{
    //   store.dispatch(addTab('Search Results', `search-results/${this.props.docId}/${this.props.vendorNo}/${this.state.checkList.toString().replace('/','-')}`));
    //   history.push(`/search-results/${this.props.docId}/${this.props.vendorNo}/${this.state.checkList.toString().replace('/','-')}`);
    // }
  }
  handleCheckBox = (e) => {
    if (!this.state.checkList.includes(e.target.value)) {
        this.setState({ checkList: [...this.state.checkList, e.target.value] })
    }
    else {
        let double = this.state.checkList.filter(el => el !== e.target.value);

        this.setState({ checkList: double })
    }
}
handleCheckBoxAll=(e)=>{
  this.setState({checkList:[]});
  let data=[]
   this.setState({checkListAll:!this.state.checkListAll},()=>{
     if(this.state.checkListAll){
       this.state.locatedocuments.forEach(val=>{
         data.push(val.key)
       })
       this.setState({checkList:data})
     }else
     this.setState({checkList:[]})
   })
}
  render() {
    return (
      <div className="checkdetailsContent">
        <div className="checkdetailsbox">
          <div className="checkdetailsheading">
            <h5> Check Details</h5>
          </div>
          <div style={{ paddingTop: "1%" }}>
            <Row xs={12}>
              <Col xs={5}>
                <Col><label>Check# :&nbsp;</label>{this.props.checkNo!='null'?this.props.checkNo:""}</Col>
                <Col><label>Vendor :&nbsp;</label>{this.props.vendorNo}</Col>
                <Col style={{ marginTop: "10px" }}>
                  <label style={{ fontWeight: "700", fontSize: "17px" }}>Locate Documents</label>
                </Col>
                <Col>
                    <input type="checkbox" checked={this.state.checkListAll} onChange={(e) => { this.handleCheckBoxAll(e) }}  value={""} style={{ marginRight: "10px" }} />
                    <label style={{ fontSize: "12px", fontWeight: "400" }} >  Select All </label>
                  </Col>
                {this.state.locatedocuments.map(value => {
                  return (
                    <Col>
                    
                      <input type="checkbox" checked={this.state.checkList.includes(value.key)} onChange={(e) => { this.handleCheckBox(e) }}  value={value.key} style={{ marginRight: "10px" }} />
                      <label style={{ fontSize: "12px", fontWeight: "400" }} >  {value.label} </label>
                    </Col>
                  );
                })}
                <Col style={{ marginTop: "15px" }} >
                  <Button style={{ marginRight: "10px" }} disabled={this.state.checkList.length===0}onClick={() => this.locateNavigate()} bsStyle="success" >Locate</Button>
                  <Button bsStyle="primary" onClick={() => this.requestNavigate()} >Request Info</Button>
                </Col>
              </Col>
              {/* <Col xs={1}>
              </Col> */}
              <Col xs={7}>
                <PDFObject containerId={"pdf-view"+this.props.checkNo.toString()} url={this.state.pdfdocument} height="70vh" />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckDetails;
