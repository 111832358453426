//any side effects or async calls

import { put } from "redux-saga/effects";
import request from "../../utils/request";
import { call } from "redux-saga/effects";
import { API_URL_SW, authorization, API_URL } from "../../config/apiConfig";
import { toast } from "react-toastify";
import { getSubmitRequestId,uploadSubmitFile } from "../actions/newRequestAction";
import { ENV } from "../../common/sysco-ui-components/config/constant";
import Axios from "axios";
import {apigeeTokenGeneration }from "../../utils/request"


export function* createNewRequest(action) {
    const requestURL = `${API_URL_SW}/submit-new-request`;
    const requestAttrs = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            authorization: authorization()
        },
        body: JSON.stringify(action.payload[0])
    };
    try {
        const dashBoardResponse = yield call(request, requestURL, requestAttrs);
        if (dashBoardResponse.rows[0].message.includes("SUCCESS")) {
            toast.success("New request submitted successfully");
            // if(action.payload[1])
             yield put(getSubmitRequestId([action.payload[1],action.payload[0],action.payload[2]]))
        }else 
        toast.error("New request failed",{
            autoClose: false
          })
    } catch (err) {
        if (err.toString().includes('401')) {
          apigeeTokenGeneration(action);
           }else{
               toast.error("New request failed",{
                autoClose: false
              })
              // console.log(err);
           }
    }
}


export function* uploadFile(action) {
    // if(action.payload[1]){
        // console.log(action,"action3")

    let viewData = {
        documentType: "Vendor Requests",
        businessCategory: "U.S. Broadline OpCos",
        opco: "Corporate",
        functionDepartment: "BSCC Accounts Payables",
        categories: ENV()==='-dev'?[{"categoryId":12883172,"attributeId":"12883172_2","attributeValue":action.payload[0]}]:ENV()==='-stg'?[{"categoryId":24962017,"attributeId":"24962017_2","attributeValue":action.payload[0]}]:ENV()==='-prod'?[{"categoryId":100846470,"attributeId":"100846470_2","attributeValue":action.payload[0]}]:[]
    };
      const formData = new FormData();
      action.payload[1] && formData.append("file", action.payload[1]);
        formData.append("upload", JSON.stringify(viewData));
        action.payload[1] &&  formData.append("fileName", "");
        action.payload[2] &&  formData.append("docIds",action.payload[2]);
      
    const requestURL = `${API_URL}${!action.payload[2] ?'supplier-web/upload-documents': 'supplier-web/upload-docids'}`;
    const requestAttrs = {
        method: "POST",
        headers: {
            authorization: authorization()
        },
        body:formData
    };
    try {
         yield call(request, requestURL, requestAttrs);
    } catch (err) {
        if (err.toString().includes('401')) {
        apigeeTokenGeneration(action);
           }
       // console.log(err);
    }
// }
}


export function* getRequestId(action) {
    // console.log(action,"action2")

    const requestURL = `${API_URL_SW}/get-request-id?vendorNumber=${localStorage.getItem("vendorNumber")}`;
    const requestAttrs = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: authorization()
        },
    };
    try {
        const response = yield call(request, requestURL, requestAttrs);
        if(response.rows[0].response){
            Axios({
                url: `${API_URL_SW}/update-existing-request`,
                method: "POST",
                headers: {
                  authorization: authorization()
                                },
                data:{
                  "requestId":response.rows[0].response,
                  "replyMessage":action.payload[1].requestInfo
                }
              })
                .then(res => {
                //   Loader(false)
                //   this.props.getDetails()
                //   this.setState({replyMsg:""})
                //   document.getElementById("replyMsgText").value="";
                })
                .catch(err => {
                });
               if(action.payload[0])
               yield put(uploadSubmitFile([response.rows[0].response,action.payload[0],""]))
               if(action.payload[2])
               yield put(uploadSubmitFile([response.rows[0].response,"",action.payload[2]]))
        }
    //    ?"":""
    } catch (err) {
        if (err.toString().includes('401')) {
        apigeeTokenGeneration(action);
           }
        //console.log(err);
    }
}