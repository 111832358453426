import React from 'react';
import SearchComponent from '../../components/searchComponent';
import { connect } from "react-redux";
import { SearchAction, SearchResult } from '../../store/actions/searchAction';
import history from '../../utils/history';
class Search extends React.Component {
    static displayName = 'Search';

    handleSearch = (data) => {
        this.props.handleSearch(data);
    }
    showDocDetails = (docName) => {
        this.props.showDocDetails(docName);
    }
    componentDidMount(){
        !localStorage.getItem("authToken") && history.push("/")
    }
    render() {
        return (
            <div>
                <SearchComponent
                    handleSearch={this.handleSearch}
                    searchList={this.props.searchReducer.SearchList}
                    showDocDetails={this.props.showDocDetails}
                    data={this.props.data}
                    delete={this.props.delete} 
                    keyval={this.props.keyval}
                />
            </div>
        )
    }
}
export function mapDispatchToProps(dispatch) {
    // eslint-disable-line
    return {
        handleSearch: data => { dispatch(SearchAction(data)) },
        showDocDetails: data => { dispatch(SearchResult(data)) }

    };
}

function mapStateToProps(state) {
    return state;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps)(Search);
