import React, { Component } from "react";
import { connect } from "react-redux";
import CheckDetails from "../../components/checkDetails/index";

class CheckDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <CheckDetails docId={this.props.data.docId} vendorNo={this.props.data.vendorNo}
    checkNo={this.props.data.checkNo}/>;
  }
}

export default connect()(CheckDetailsContainer);
