import React from 'react';
import { connect } from "react-redux";
import ChangePassComponent from '../../components/changePassword';
import history from '../../utils/history';

class Change_Password extends React.Component{
    static displayName = 'Change_Password';
    state={
        
    }
    
    componentDidMount(){
      !localStorage.getItem("authToken") && history.push("/")
    }
    
    render(){
        return(
            <div><ChangePassComponent loginMsg={this.props.loginMsg} /></div>
            
        )
    }
    
}
export function mapDispatchToProps(dispatch) {
   
    return (<div></div>)
        
    
  }
  
  function mapStateToProps(state) {
    return {
      loginMsg:state.DashboardReducer}
  }
  
export default connect(mapStateToProps,mapDispatchToProps)(Change_Password);