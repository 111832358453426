import { Component } from "react";
import { Auth } from "aws-amplify";

class RedirectLogin extends Component {

    constructor(props) {
        super(props);
        console.log('Redirecting to login');
        Auth.federatedSignIn({ provider: 'SupplierwebSSO'});
    }
}

export default RedirectLogin;