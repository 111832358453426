import { ADDTAB, DELTAB, CLEAR_TAB } from "../actions/actionTypes";

const INITIAL_STATE = {
  tabList: [],
  activeKey: "",
  data:{},
  title:""
};

const tabViewReducer = (state , action) => {
  if (!state){
    return INITIAL_STATE;
}
  switch (action.type) {
    case ADDTAB:
      if (
        !state["tabList"].some(el => el.eventKey === action.payload.eventKey)
      ) {
        return {
          tabList: [...state["tabList"].slice(action.index),action.payload],
          activeKey: action.payload.eventKey,
          data: action.payload.data,
          title: action.payload.title
        };
      } else {
        return {
          tabList: state["tabList"],
          activeKey: action.payload.eventKey,
          data: action.payload.data,
          title: action.payload.title
        };
      }
    case DELTAB:
      if (
        state["tabList"].some(el => el.eventKey === action.payload.eventKey)
      ) {
        state["tabList"] = state["tabList"].filter(
          val => val.eventKey !== action.payload.eventKey
        );
        return {
          tabList: [...state["tabList"].slice(action.index)],
          activeKey: action.payload.type?action.payload.type:state["tabList"][state["tabList"].length-1].eventKey,
          data: action.payload.data,
          title: action.payload.title
        };
      } 
      else {
        return {
          tabList: state["tabList"],
          activeKey: action.payload.eventKey,
          data: action.payload.data,
          title: action.payload.title
        };
      }
      case CLEAR_TAB:
        return {
          tabList: [],
          activeKey: "",
          data:{},
          title: ""
        };
    default:
      return state;
  }
};

export default tabViewReducer;
