import cookie from "react-cookies";
import { environment } from "../common/sysco-ui-components/config/constant";
import Axios from "axios";

// Local Development
// export const API_URL = `https://api${environment() == "-dev" ? "-qa" : ""}.sysco.com/otapi/1.0/`
// export const API_URL_SW = `https://api${environment() == "-dev" ? "-qa" : ""}.sysco.com/otapi/1.0/supplier-web`

export const API_URL = `https://api-gateway${environment() == "-prod" ? "" :environment()}.sysco.com/services/otapi/`
export const API_URL_SW = `https://api-gateway${environment() == "-prod" ? "" :environment()}.sysco.com/services/otapi/supplier-web`

// Deployment
// export const API_URL = `${process.env.REACT_APP_BURL}/services/otapi/`
// export const API_URL_SW = `${process.env.REACT_APP_BURL}/services/otapi/supplier-web`

// export const API_URL = `http://localhost:7001`;
// export const API_URL_SW = `http://localhost:7001/supplier-web`;

export const DP_ENV = environment();

export const authorization = () => {
  return "Bearer " + cookie.load("token");
};

export const authorizationJwt = () => {
  return cookie.load("jwt_token");
};

export function prodLogin() {
  if (environment() === '-dev') {
    return '-np';
  } else return ""
}

export function reCallService(action) {
  return action;
}


export async function  getToken(){
  const res= await Axios({
    url: `${API_URL_SW}/get-token`,
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      authorization: authorization(),
    }  })
    .then(loginResponse => {
      if (loginResponse.data.rows && loginResponse.data.rows.length !== 0 && loginResponse.data.rows[0].message) {
        return loginResponse.data.rows[0].message
      } else return null
    })
    .catch(err => { 
      return null
    })
    return await res
}
