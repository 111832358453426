import React,{Component} from 'react'
import {connect} from 'react-redux'
import './style.css'


class LoadingIndicator extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            spinner : false
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.spinner && nextProps.spinner.spinner_status){
            if(!this.state.spinner){
                this.setState({spinner : true})
            }
        }else if(!nextProps.spinner.spinner_status){
            if(this.state.spinner){
                this.setState({spinner : false})
            }
        }
    }

    render(){
        return(
            this.state.spinner ? ( 
            <div className="loadingIndicator" id="loadingIndicator">
                <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
                </div>
            </div> ): null 

        )
    }
}

function mapStateToProps(state) {
    return{
        spinner : state.loadingIndicatorreducer
    }
  }

export default connect(mapStateToProps,null)(LoadingIndicator)