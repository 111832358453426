import React, { Component } from "react";
import ResponseDetails from "../../components/responseDetails/index";
import { connect } from "react-redux";
import { getResponseDetails,getAttachments} from "../../store/actions/responseDetailAction";

class ResponseDetailsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillReceiveProps(newProps){
    this.setState({
    requestAttachment:newProps.responseDetailReducer.requestAttachmentData});

  }

  componentDidMount() {
    // this.getDetails();
    // this.props.getAttachments(this.props.data.id);
}
  

  getDetails=()=>{
    this.props.getDetails(this.props.data.id);
  }
  render() {
    return (
      <ResponseDetails requestAttachment={this.state.requestAttachment} responseDetails={this.props.responseDetailReducer.responseDetails} getDetails={this.getDetails} requestId={this.props.data.id} getAttachments={this.props.getAttachments}
      tabDetails={this.props.tabDetails} deleteTab={this.props.delete} keyval={this.props.keyval}/>
    );
  }
}



function mapStateToProps(state) {
  return {
    responseDetailReducer: state.ResponseDetailReducer,
    tabDetails: state.tabViewReducer,

  };
}
const mapDispatchToProps = dispatch => {
  return {
    getDetails: (id) => {
      dispatch(getResponseDetails(id))
    },
    getAttachments:(id)=>{
      dispatch(getAttachments(id))

    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResponseDetailsContainer);
