
import * as actionTypes from './actionTypes';


export const auth=(username,password,captchaToken,captchaBypassKey)=>{
    const data={
        username:username,
        password:password,
        captchaToken:captchaToken,
        captchaBypassKey:captchaBypassKey,
        sssLogin:false
    }
    return{
       type:actionTypes.AUTH_LOGIN,
       payload:data
    }
}

export const authSss=(username)=>{
    const data={
        username:username,
        sssLogin:true
    }
    return{
       type:actionTypes.AUTH_LOGIN,
       payload:data
    }
}

export function loginSuccess(data){
    
    return{
       type:actionTypes.LOGIN_SUCCESS,
       payload:data
}
}



export function loginFailed(data){
    
    return{
       type:actionTypes.AUTH_FAIL,
       payload:data
}
}


export function serviceCallFailed(data){
    
    return{
       type:actionTypes.SERVICE_FAIL,
       payload:data
}
}

export function inactiveVendor(data){
    
    return{
       type:actionTypes.INACTIVE_VENDOR,
       payload:data
}
}

export function captchaFailed(data){
    
    return{
       type:actionTypes.CAPTCHA_FAILED,
       payload:data
    }
}

export function loginFailedPasswordExpired(data){
    
    return{
       type:actionTypes.LOGIN_FAILED_PASSWORD_EXPIRED,
       payload:data
    }
}
export function loginFailedChangeAfterReset(data){
    
    return{
       type:actionTypes.LOGIN_FAILED_CHANGE_AFTER_RESET,
       payload:data
    }
}

export function loginFailedAccountLocked(data){
    
    return{
       type:actionTypes.LOGIN_FAILED_ACCOUNT_LOCKED,
       payload:data
    }
}