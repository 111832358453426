import React, { Component, Fragment } from "react";
import "./style.css";
import { Row, Col, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import moment from "moment";
import { ENV, env } from "../../common/sysco-ui-components/config/constant";
import  cookie from "react-cookies";
import { toast } from "react-toastify";
var moment = require('moment-timezone');
class NewRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoicedate: "",
      paymentdata: "",
      email: "",
      confirmEmail: "",
      paymentAmount: "",
      paymentNumber: "",
      invoiceNumber: "",
      poNumber: "",
      ocNumber: "",
      requestInfo: "",
      paymentDate: "",
      errorText: "",
      file:null,
      fileName:""
    };
    this.viewData = {
      documentType: "Vendor Requests",
      businessCategory: "U.S. Broadline OpCos",
      opco: "Corporate",
      functionDepartment: "BSCC Accounts Payables",
      categories: ENV()==='-dev'?[{"categoryId":12883172,"attributeId":"12883172_2","attributeValue":localStorage.getItem("vendorNumber")}]:ENV()==='-stg'?[{"categoryId":24962017,"attributeId":"24962017_2","attributeValue":localStorage.getItem("vendorNumber")}]:ENV()==='-prod'?[{"categoryId":100846470,"attributeId":"100846470_2","attributeValue":localStorage.getItem("vendorNumber")}]:[]
    };
  }

  handleDate = (date, name) => {
    // let newDate = moment(date).format("MM-DD-YY");
    this.setState({ [name]: date });
  };
  submitRequest = () => {
    // if (this.validityState()) {
      let data = {
        emailAddress: this.state.email,
        poNumber: this.state.poNumber,
        ocNumber: this.state.ocNumber,
        vendorInvoiceNumber: this.state.invoiceNumber,
        vendorInvoiceDate: this.state.invoicedate? moment(this.state.invoicedate).format("MM-DD-YYYY:HH:mm:ss"):'',
        paymentNumber: this.state.paymentNumber,
        paymentDate: this.state.paymentDate?moment(this.state.paymentDate).format("MM-DD-YYYY:HH:mm:ss"):'',
        paymentAmount: this.state.paymentAmount,
        requestInfo: this.state.requestInfo,
        vendorNumber: this.props.data.vendorNo?this.props.data.vendorNo:localStorage.getItem("vendorNumber"),
        requestDate: moment().tz("America/New_York").format("MM-DD-YYYY:HH:mm:ss")
       
      };
      this.props.submitRequest([data,this.state.file,this.props.data.id]);
      this.props.delete(this.props.keyval)    // } else {
    //   console.log("failed");
    // }
  };
  validityState = () => {
    let errorText = "";
    let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (this.state.email === "") {
      errorText = "Email Can't be empty";
    } else if (!filter.test(this.state.email)) {
      errorText = "Invalid email address";
    } else if (this.state.confirmEmail === "") {
      errorText = "Please confirm the email";
    } else if (this.state.email !== this.state.confirmEmail) {
      errorText = "Email id doesn't match";
    }
    if (errorText === "") {
      return true;
    }else{
      return false;
    }
  };
  handleChange = e => {
    let value = e.target.value;
    this.setState({ [e.target.name]: value });
  };


  _handleFileChange(e) {
    e.preventDefault();
    if((e.target.files[0].size/1024/1024)>50){
      toast.error("File must be smaller than 50MB!")
      return false
    }else{
      let reader = new FileReader();
      let file = e.target.files[0];
      let fileName = e.target.files[0].name;
      reader.onloadend = () => {
        this.setState({
          file: file,
          fileName:fileName
          // imagePreviewUrl: reader.result
        },()=>{
        });
      }
      reader.readAsArrayBuffer(file)
    }
  }
  render() {

    return (
      <div className="supplierContent">
        <div className="supplierbox">
          <div className="supplierheading">
            <h5> New Request</h5>
          </div>
          <div style={{ paddingTop: "1%" }}>
            <Row xs={12}>
              <Col xs={6}>
                <Col xs={1} style={{ padding: "0px" }}>
                  Date:
                </Col>
                <Col xs={8} style={{ paddingLeft: "0px" }}>
                  <input
                    type="text"
                    class="form-control input-sm a"
                    value={moment().tz("America/New_York").format('MM/DD/YYYY HH:mm:ss A')}
                    // value={moment().subtract('hours', 1).format("MM/DD/YYYY HH:mm:ss A")}
                  />
                </Col>
              </Col>
              <Col xs={6}>
                <Row>
                  <Col xs={4} style={{ paddingRight: "0px" }}>
                    Supplier's E-mail Address:
                    <p style={{ color: "red", display: "inline"}}>*</p>
                  </Col>
                  <Col xs={8}>
                    <input
                      type="text"
                      class="form-control input-sm a"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={4} style={{ paddingRight: "0px" }}>
                    Confirm Supplier's E-mail Address:
                    <p style={{ color: "red", display: "inline"}}>*</p>
                  </Col>
                  <Col xs={8}>
                    <input
                      type="text"
                      class="form-control input-sm a"
                      name="confirmEmail"
                      value={this.state.confirmEmail}
                      onChange={this.handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                <Col xs={4} style={{ paddingRight: "0px" }}>
                  </Col>
                  <Col xs={8}>

                {this.state.email && this.state.confirmEmail && this.state.email !== this.state.confirmEmail && <div className={"newrequest-errortext"}>Email Id's doesn't match.</div>}
                  </Col>
                </Row>
              </Col>
              <Col xs={12}>
                Request Info
                <p style={{ color: "red", display: "inline"}}>*</p>
                <textarea
                  maxLength="1024"
                  class="form-control"
                  rows="4"
                  name="requestInfo"
                  value={this.state.requestInfo}
                  onChange={this.handleChange}
                />
              </Col>
      
              <Col xs={12} style={{ marginTop: "10px" }}>
                <Col xs={4} style={{ paddingLeft: "0px " }}>
                  <Button
                    bsStyle="success"
                    onClick={this.submitRequest}
                    disabled={!this.validityState() || !this.state.requestInfo}
                  >
                    Submit Request
                  </Button>
                </Col>
                <Col xs={4}>
                  <input type="file" name="Choose File" accept=".bmp,.doc,.docx,.gif,.jpeg,.jpg,.pdf,.png,.rtf,.tiff,.tif,.txt,.xls,.xlsx,.msg"  onChange={(e) => this._handleFileChange(e)} />
                </Col>
                <Col xs={4}>
                  Max. 50mb. - Allowed formats: doc, docx, xls, xlsx, pdf, jpg, jpeg, tiff, tif,
                  gif, bmp, png, txt, msg
                </Col>
              </Col>
            {this.props.data.id &&  <Fragment>
              <Col xs={4}></Col>
              <Col xs={4}>
              <div className="supplierheading" style={{height:"23px"}}>
              <h5> Document Name</h5>
             </div>
            </Col>
            <Col xs={12} style={{marginBottom: '8px'}}>
            <Col xs={4}></Col>
            <Col xs={4}>
            <div className="dataContent">
                        <a target="_blank"  rel="noopener noreferrer" href={`https://les${env()}.sysco.com/otcs/cs.exe/api/v2/nodes/${this.props.data.id}/content?OTDSTicket=${cookie.load("serviceToken")}`} >
                          {" "}
                          Check/ACH
                        </a>
                      </div>
                      </Col>
                      </Col>
                      </Fragment>}
              <Col xs={12}>
                <div>
                  <label>
                    Please provide the following additional request-related
                    information, if available:
                  </label>{" "}
                </div>
                <Col xs={6}>
                  <Row>
                    <Col xs={3}>PO#:</Col>
                    <Col xs={8}>
                      <input
                        type="text"
                        class="form-control input-sm a"
                        name="poNumber"
                        value={this.state.poNumber}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>OC#:</Col>
                    <Col xs={8}>
                      <input
                        type="text"
                        class="form-control input-sm a"
                        name="ocNumber"
                        value={this.state.ocNumber}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Invoice #:</Col>
                    <Col xs={8}>
                      <input
                        type="text"
                        class="form-control input-sm a"
                        name="invoiceNumber"
                        value={this.state.invoiceNumber}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Invoice Date:</Col>
                    <Col xs={8} style={{ marginBottom: "10px",width: "262px"}}>
                      <DatePicker
                        className="form-control input-sm "
                        value={this.state.invoicedate}
                        selected={this.state.invoicedate}
                        onChange={date => {
                          this.handleDate(date, "invoicedate");
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Payment #:</Col>
                    <Col xs={8}>
                      <input
                        type="text"
                        class="form-control input-sm a"
                        name="paymentNumber"
                        value={this.state.paymentNumber}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Payment Date:</Col>
                    <Col xs={8} style={{ marginBottom: "10px",width: "262px" }}>
                      <DatePicker
                        className="form-control input-sm"
                        value={this.state.paymentDate}
                        selected={this.state.paymentDate}
                        onChange={date => {
                          this.handleDate(date, "paymentDate");
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={3}>Payment Amount:</Col>
                    <Col xs={8}>
                      <input
                        type="number"
                        class="form-control input-sm a"
                        name="paymentAmount"
                        value={this.state.paymentAmount}
                        onChange={this.handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
                {/* <Col xs={12}> */}
                <Button
                    bsStyle="primary"
                    onClick={()=> this.props.delete(this.props.keyval)
                    }
                    style={{
                      marginBottom: "8px",
                      position: 'absolute',
                      bottom: "0px",
                      right: "10px"}}
                  >
                   Close
                  </Button>
                {/* </Col> */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default NewRequest;
