//any side effects or async calls

import { put } from "redux-saga/effects";
import request from "../../utils/request";
import * as actions from "../actions/dashboardAction";
import { call } from "redux-saga/effects";
import moment from "moment";
import { API_URL_SW, authorization } from "../../config/apiConfig";
import { Loader } from "../../common/sysco-ui-components/services/commonServices";
import { store } from "../..";
import { RECENT_LOADER, DAILY_LOADER, CS_LOADER, VENDOR_LOADER } from "../actions/actionTypes";
import { toast } from "react-toastify";
import {apigeeTokenGeneration }from "../../utils/request"

export function* dashboard(action) {
  //generator
  //each step with yield

  const requestURL = `${API_URL_SW}/supplierweb-dashboard`;

  const requestAttrs = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: authorization()
    }
  };

  try {
     yield call(request, requestURL, requestAttrs);
  //  yield put(actions.dashboardData(dashBoardResponse.rows[0].searchResultsLists));
  } catch (err) {}
}

export function* getRecentChecks(action) {
  store.dispatch({type:RECENT_LOADER,payload:true})
  // const requestURL = `${API_URL_SW}/recent-check-results?inputlabel1=${action.payload}&inputlabel2=${moment().subtract(14, "days").format('YYYYMMDD')}`;
  const requestURL = `${API_URL_SW}/get-recent-check?section=RecentChecks&fromDate=${moment().subtract(14, "days").format('MMDDYYYY')}&vendorNumber=${action.payload.length!==0?encodeURIComponent(action.payload.toString().replace(/,/g,"|")):""}`;

  const requestAttrs = {
    method: "GET",
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization: authorization()
    },
    // body: formData
  };
  try {
    const dashBoardResponse = yield call(request, requestURL, requestAttrs);
    let data = dashBoardResponse.rows[0].recentChecks;
    yield put(actions.setRecentChecks(data ? data : []));
    if(data){
      // setTimeout(() => {
        //   Loader(false)
        // }, 1000);
      }
      store.dispatch({type:RECENT_LOADER,payload:false})
  } catch (err) {
    store.dispatch({type:RECENT_LOADER,payload:false})
    // setTimeout(() => {
    //   Loader(false)
    // }, 1000);
   // console.log(err);
    if (err.toString().includes('401')) {
      apigeeTokenGeneration(action);
     }else{
       toast.error("Failed to load Recent Checks, please reload.",{
        autoClose: false
      })
     }
  }
}

export function* getPendingPayableReport(action) {
  store.dispatch({type:DAILY_LOADER,payload:true})
  // console.log(action.payload,"ll")
  //const requestURL = `${API_URL_SW}/daily-pending-payable-report?inputlabel1=${action.payload}&inputlabel2=${moment().day()==1?moment().subtract(3, "days").format('YYYYMMDD'):moment().subtract(1, "days").format('YYYYMMDD')}`;
  const requestURL = `${API_URL_SW}/get-recent-check?section=PendingPayables&fromDate=${moment().day()===1?moment().subtract(3, "days").format('MMDDYYYY'):moment().subtract(1, "days").format('MMDDYYYY')}&vendorNumber=${action.payload.length!==0?encodeURIComponent(action.payload.toString().replace(/,/g,"|")):""}`;

  const requestAttrs = {
    method: "GET",
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization: authorization()
    },
    // body: formData
  };
  try {
    const dashBoardResponse = yield call(request, requestURL, requestAttrs);
    Loader(false)
    let data = dashBoardResponse.rows[0].recentChecks;
    yield put(actions.setPendingPayableReport(data ? data : []));
    store.dispatch({type:DAILY_LOADER,payload:false})

  } catch (err) {
    store.dispatch({type:DAILY_LOADER,payload:false})
   // console.log(err);
    if (err.toString().includes('401')) {
      apigeeTokenGeneration(action);
     }else{
      toast.error("Failed to load Daily pending payable report, please reload.",{
        autoClose: false
      })
    }
  }
}

export function* getCustomerService(action) {
  //generator
  //each step with yield
  store.dispatch({type:CS_LOADER,payload:true})
  const requestURL = `${API_URL_SW}/get-customer-service?vendorNumber=${action.payload[0]}&hasResponse=${action.payload[1]}&fromDate=&toDate=`;
  const requestAttrs = {
    method: "GET",
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization: authorization()
    }
  };
  try {
    const dashBoardResponse = yield call(request, requestURL, requestAttrs);
    let data = dashBoardResponse.rows[0];
    yield put(actions.setCustomerServiceReport(data ? data : {}));
    store.dispatch({type:CS_LOADER,payload:false})
  } catch (err) {
  //  console.log(err);
    store.dispatch({type:CS_LOADER,payload:false})
    if (err.toString().includes('401')) {
      apigeeTokenGeneration(action);
     }else{
      toast.error("Failed to load Customer Service, please reload.",{
        autoClose: false
      })
    }
  }
}
export function* getVendorDownloadLink(action) {
  //generator
  //each step with yield
  store.dispatch({type:VENDOR_LOADER,payload:true})
  const requestURL = `${API_URL_SW}/get-vendor-download-links?vendorId=${action.payload}`;
  const requestAttrs = {
    method: "GET",
    headers: {
      // "Content-Type": "multipart/form-data",
      authorization: authorization()
    }
  };
  try {
    const response = yield call(request, requestURL, requestAttrs);
    let data = response.rows[0].vendorDownloadLinks;
    yield put(actions.vendorDownloadLinkSuccess(data ? data : []));
    store.dispatch({type:VENDOR_LOADER,payload:false})
  } catch (err) {
    store.dispatch({type:VENDOR_LOADER,payload:false})
    //console.log(err);
    if (err.toString().includes('401')) {
      apigeeTokenGeneration(action);
     }else{
      toast.error("Failed to load Vendor download links, please reload.",{
        autoClose: false
      })
    }
  }
}
