import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.css";
import history from "../../../../utils/history";
import * as actions from "../../store/actions/tabViewAction";
import { REFRESH } from "../../config/constant";
import { Tabs } from "antd";
import cookie from "react-cookies";
import { Row, Col, Button } from "react-bootstrap";
import { WarningOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
const timeOptions = {
  hour12: true,
  hour: 'numeric',
  minute: '2-digit',
  second: '2-digit',
}
const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
class TabView extends Component {
  constructor(props, context) {
    super(props, context);
    this.newTabIndex = 0;
    const panes = [
    ];
    
    this.state = {
      activeTab: this.props.tabDetails.activeKey,
      refresh: true,
      activeKey: panes.length !== 0 ? panes[0].key : "",
      panes,
      show:false,
      showAlert:true,
      passwordExpiryDate:cookie.load('accountExpirationTime')? new Date(cookie.load('accountExpirationTime')).toLocaleString('en-US',{ ...timeOptions, ...dateOptions }) : null
    };
  }

   passwordExpiryAlert = (days) =>{
     let currentDate = new Date();
     let passwordExpirationDate = new Date(cookie.load('accountExpirationTime'));
     let dateDifference = Math.round((passwordExpirationDate.getTime() - currentDate.getTime()) / (1000 * 3600 * 24));
     this.setState({
       passwordExpiryDate: cookie.load('accountExpirationTime')? new Date(cookie.load('accountExpirationTime')).toLocaleString('en-US',{ ...timeOptions, ...dateOptions }) : null

     })

     if (dateDifference <= days) {
       this.setState({ show: true });
     }
     else {
       this.setState({ show: false });
     }
     cookie.load('accountExpirationTime')? this.setState({showAlert:this.state.showAlert}) :this.setState({showAlert:true});
  }
  onChange = activeKey => {
      this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  add = (component, payload, label) => {
    const { panes } = this.state;
    let titleVal = label.includes("(") ? label.substring(label.lastIndexOf("(") + 1, label.lastIndexOf(")")).replace(/_/g, " ") : label
    let dup = panes.filter(val => val.title === titleVal)
    if (dup.length === 0 ) {
      const activeKey = `${label.includes("(") ? label.substring(label.lastIndexOf("(") + 1, label.lastIndexOf(")")).replace(/_/g, " ") : label}`;
        panes.push({
          title: titleVal ? titleVal : "New Tab",
          content: "New Tab Pane",
          comp: component,
          key: activeKey,
          data: payload
        });
        this.setState({ panes, activeKey });
    }else if(titleVal.includes('Search Results:')){
      this.deleteTab(titleVal,true,component,payload, label);
    }
    else {
      this.onChange(dup[0].key)
    }
  }

  deleteTab = (targetKey,flag, component,payload, label) => {
    if (this.state.panes.length !== 1) {
      let { activeKey } = this.state;
      let lastIndex;
      this.state.panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      const panes = this.state.panes.filter(pane => pane.key !== targetKey);
      if (panes.length && activeKey === targetKey) {
        if (lastIndex >= 0) {
          activeKey = panes[lastIndex].key;
        } else {
          activeKey = panes[0].key;
        }
      }
      this.setState({ panes, activeKey },()=>{
        if(flag){
          this.add(component,payload,label);
        }
      }); 
    }
  }

  remove = targetKey => {
      this.deleteTab(targetKey)
  };


  componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ activeTab: nextProps.tabDetails.activeKey }, () => {
      nextProps.tabDetails.activeKey ? this.add(this.state.activeTab, nextProps.tabDetails.data, nextProps.tabDetails.title):this.setState({panes:[]})
    });
    setTimeout(this.passwordExpiryAlert(15), 1500);
  }

  refresh = () => {
    history.push({
      pathname: REFRESH,
      response: {
        url: window.location.pathname + window.location.search
      }
    });
  };
  render() {
    // let data = "";
    // if (this.props.tabDetails !== undefined) {
    //   data = this.props.tabDetails["tabList"];
    // }
    return (
      <div id="antTab">
        {this.state.showAlert && this.state.show && this.state.passwordExpiryDate && <div style={{ textAlign:"center"}}>
        <div  className="alertboxheader">
          <Row style={{textAlign:'center', width:'100%'}}><Col xs={11} > <WarningOutlined /> &nbsp; Your password expires on {this.state.passwordExpiryDate}. Please consider changing the password.</Col>
          <Col xs={1} style={{textAlign:'right'}}><button class="close-button" type="button" aria-label="close" onClick={() =>{this.setState({show:false, showAlert:false})}}>X</button></Col></Row>
        </div>
        </div>}
      <Tabs
        hideAdd
        onChange={this.onChange}
        activeKey={this.state.activeKey}
        type="editable-card"
        onEdit={this.onEdit}
        size='small'
        animated={false}
      >
        {this.state.panes && this.state.panes.length!==0 && this.state.panes.map(pane => (
          <TabPane tab={pane.title} key={pane.key} forceRender={false}>
            <pane.comp data={pane.data} delete={this.remove} keyval={this.state.activeKey}/>
          </TabPane>
        ))}
      </Tabs>
    </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    tabDetails: state.tabViewReducer //reffered as this.props.tabDetails
  };
};
const mapDispatchToProps = dispatch => {
  return {
    OnDelTab: eventKey => {
      dispatch(actions.delTab(eventKey));
    }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabView);
