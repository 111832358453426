
import * as actionTypes from "./actionTypes";

export function createNewRequest(data) {
    return {
        type: actionTypes.CREATE_NEW_REQUEST,
        payload: data
    };
}
export function newRequestSuccess(data) {
    return {
        type: actionTypes.NEW_REQUEST_SUCCESS,
        payload: data
    };
}
export function uploadSubmitFile(data) {
    return {
        type: actionTypes.UPLOAD_DATA,
        payload: data
    };
}
export function getSubmitRequestId(data) {
    return {
        type: actionTypes.GET_REQUEST_ID,
        payload:data
        };
}