import React, { Component } from "react";
import "./styles.css";
import { Row, Col, Button } from "react-bootstrap";
import cookie from "react-cookies";
import Axios from "axios";
import { DP_ENV } from "../../config/apiConfig";
import { toast } from "react-toastify";
import { Switch } from 'antd';


class ChangePassModalComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPass: "",
      newPass: "",
      reEnterNewPass: "",
      passwordPolicyError: "",
      errorMessage: props.error,
      showPassword: false,
      invalidCred: false,
      buttonDisabled:false

    };

  }
  componentWillReceiveProps = (newProps) => {
    this.setState({ errorMessage: newProps.error });
  }
  onShowpassword = val => {
    this.setState({ showPassword: val });
  };

  handleCurrentPassChange = e => {
    this.setState({ currentPass: e.target.value, invalidCred: false });
  };
  handleNewPassChange = e => {
    this.setState({ newPass: e.target.value });
  };
  handleReEnterPassChange = e => {
    this.setState({ reEnterNewPass: e.target.value });
  };
  changePassword = () => {
    if (this.state.currentPass !== this.state.newPass) {
      this.setState({buttonDisabled:true});
      Axios({
        url: `/change-password`,
        method: "POST",
        headers: {
          "x-env": DP_ENV
        },
        data: {
          oldPassword: this.state.currentPass,
          newPassword: this.state.newPass,
          userName: cookie.load("userName")
        }
      })
        .then(resa => {
          let res = resa.data.changePasswordResponse

          if (res.message === "SUCCESS") {
            this.setState({ passwordPolicyError: "" });
            toast.success("Password changed successfully", { autoClose: false });
            this.props.onModalCallback(false);
          } else {
            this.setState({buttonDisabled:false});
            if (res.error.includes("com.opentext.otds.usergroup.PasswordException:") || res.error.includes("PASSWORD_TOO_WEAK:")) {
              this.setState({ passwordPolicyError: res.error.toString().split(':')[1] })
            }
            else if (res.error.includes("PASSWORD_IN_HISTORY")) {
              this.setState({ passwordPolicyError: "New password cannot be same as any of the previous 10 passwords" });
            }
            else if (res.error.includes('INVALID_CREDENTIALS')) {
              this.setState({ invalidCred: true });

            }
            else {
              toast.error(res.error, { autoClose: false });
            }
          }
        })
        .catch(err => {
          this.setState({buttonDisabled:false});
        })
    };
  }
  render() {
    return (
      <div >
        <div className="passheading">
          <h5 style={{ fontWeight: "700" }}> Change Password : {cookie.load("userName")} </h5>
        </div>
        <div className="Content">
          <div className="margins">
            <Row> {this.state.errorMessage == 'Change_After_Reset' && <div>Password change is required on first login or reset.</div>}
              {this.state.errorMessage == 'Password_Expired' && <div>Your password has expired and must be changed.</div>}
              <br /></Row>
            <Row>
              <Row className="bottomspace">
                <Col xs={4}>
                  <label style={{ float: "left" }}>
                    Current Password:
                  </label>{" "}
                  &nbsp;&nbsp;
                </Col>
                <Col xs={8}>
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    name="currentPass"
                    value={this.state.currentPass}
                    onChange={e => {
                      this.handleCurrentPassChange(e);
                    }}
                    className={
                      this.state.invalidCred ? "form-control highlight1" : "form-control entryBox1"
                    }
                  />
                  <p
                    style={{
                      color: "red",
                      display:
                        this.state.invalidCred ? "" : "none"
                    }}
                  >
                    The current password provided is incorrect.
                  </p>
                </Col>
              </Row>
              <Row className="bottomspace">
                <Col xs={4}>
                  <label style={{ float: "left" }}>New Password:</label>{" "}
                  &nbsp;&nbsp;
                </Col>
                <Col xs={8}>
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    name="newPass"
                    value={this.state.newPass}
                    onChange={e => {
                      this.handleNewPassChange(e);
                    }}
                    className={
                      this.state.currentPass === this.state.newPass &&
                        this.state.currentPass !== ""
                        ? "form-control highlight1"
                        : "form-control entryBox1"
                    }
                  />
                  <p
                    style={{
                      color: "red",
                      display:
                        this.state.currentPass === this.state.newPass &&
                          this.state.currentPass !== ""
                          ? ""
                          : "none"
                    }}
                  >
                    New password cannot be the same as current password.
                  </p>
                </Col>
              </Row>
              <Row className="bottomspace">
                <Col xs={4}>
                  <label style={{ float: "left" }}>
                    Re-Enter New Password:
                  </label>{" "}
                </Col>
                <Col xs={8}>
                  <input
                    type={this.state.showPassword ? "text" : "password"}
                    name="reEnterNewPass"
                    value={this.state.reEnterNewPass}
                    onChange={e => {
                      this.handleReEnterPassChange(e);
                    }}
                    className={
                      this.state.newPass !== this.state.reEnterNewPass &&
                        this.state.newPass !== "" &&
                        this.state.reEnterNewPass !== ""
                        ? "form-control highlight1"
                        : "form-control entryBox1"
                    }
                  />
                  <div
                    style={{
                      color: "red",
                      display:
                        this.state.newPass !== this.state.reEnterNewPass &&
                          this.state.reEnterNewPass !== ""
                          ? ""
                          : "none"
                    }}
                  >
                    Passwords do not match
                  </div>
                </Col>

              </Row>
              <Row>
                <Col xs={12}>
                  <div
                    style={{
                      color: "red",
                      display: this.state.passwordPolicyError !== "" ? "" : "none"
                    }}
                  >
                    {this.state.passwordPolicyError}
                  </div>
                </Col>
              </Row>
              <Row >
                <Col xs={7} style={{ textAlign: "left" }}>

                  <label>New Password Requirements:</label>
                  <ul style={{ fontSize: '11px' }}>
                    <li>Minimum length of 10 characters</li>
                    <li>Minimum 1 UPPER CASE alphabet</li>
                    <li>Minimum 1 lower case alphabet</li>
                    <li>Minimum 1 number</li>
                    <li>Minimum 1 symbol</li>
                    <li>Should NOT use any of the last 10 passwords</li>

                  </ul>
                </Col>
                <Col xs={5} style={{ textAlign: "right" }}>

                  <label>Show Passwords </label> &nbsp;

                  <Switch defaultChecked={false} onChange={e => { this.onShowpassword(e) }} />
                </Col>
              </Row>

              <Row>

                <div style={{ textAlign: "left", float: "right" }}>
                  <br />



                  <Button
                    className="btn-primary"

                    onClick={this.changePassword}
                    disabled={
                      this.state.buttonDisabled ||
                      this.state.currentPass === "" ||
                        this.state.newPass === "" ||
                        this.state.reEnterNewPass === "" ||
                        (this.state.currentPass === this.state.newPass &&
                          this.state.currentPass !== "") ||
                        (this.state.newPass !== this.state.reEnterNewPass &&
                          this.state.newPass !== "" &&
                          this.state.reEnterNewPass !== "")
                        ? true
                        : false
                    }
                  >
                    {" "}
                    Change Password{" "}
                  </Button>
                  &nbsp;
                  &nbsp;
                  <Button
                    className="btn-secondary"
                    onClick={() => { this.props.onModalCallback(false) }}
                  >
                    {" "}
                    Close{" "}
                  </Button>
                </div>
              </Row>
            </Row>
          </div>
        </div>
      </div>


    );
  }
}

export default ChangePassModalComponent;