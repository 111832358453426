import React, { Component } from "react";
import "./styles.css";
import { Row, Col, Button } from "react-bootstrap";
import { PDFObject } from "react-pdfobject";
import { store } from "../..";
import { addTab } from "../../common/sysco-ui-components/store/actions/tabViewAction";
import { env } from "../../common/sysco-ui-components/config/constant";
import cookie from "react-cookies";
import { toast } from "react-toastify";
import { API_URL_SW, authorization, getToken } from '../../config/apiConfig'
import newRequestContainer from "../../containers/newRequestContainer";
import Axios from "axios";
import { Spin } from "antd";
import { SyncOutlined } from "@ant-design/icons";

class DocDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DocumentAttributes: [],
      SearchUrl: ``,
      totInvNo: [],
      totOrdNo: [], id: "",
      spinner: false
    };
  }

  async componentDidMount() {
    let data = await getToken()
    this.setState({
      SearchUrl: `https://les${env()}.sysco.com/otcs/cs.exe/api/v1/nodes/${this.props.urlParams.id}/content?OTDSTicket=${data ? data : cookie.load("serviceToken")}`,
      id: this.props.urlParams.id
    }, () => {
    }
    )
    this.getDocAttrs();
  }

  getDocAttrs = () => {
    this.setState({ spinner: true });
    Axios({
      url: `${API_URL_SW}/get-doc-attrs?docId=${this.props.urlParams.id
        }&catName=${this.props.urlParams.docType === "Check-ACH"
          ? "SUPP WEB BSCC AP Payment"
          : "SUPP WEB BSCC AP Merchandise"
        }`,
      method: "GET",
      headers: { authorization: authorization() }
    })
      .then(docAttrRes => {
        this.setState({ spinner: false });
        if (docAttrRes.data.rows && docAttrRes.data.rows.length !== 0 && docAttrRes.data.rows[0].categories) {
          let dat = docAttrRes.data.rows[0].categories;
          this.setState({ DocumentAttributes: dat })
          let totInvoice = []; let totOrder = [];
          totOrder = dat && dat.length !== 0 && dat[0].attributes.filter(val => val.attributeName === "Total Order Control Numbers")
          totInvoice = dat && dat.length !== 0 && dat[0].attributes.filter(val => val.attributeName === "Total Vendor Invoice Numbers")
          this.setState({
            totInvNo: totInvoice.length !== 0 ? totInvoice[0].attributeValue.toString() : "0",
            totOrdNo: totOrder.length !== 0 ? totOrder[0].attributeValue.toString() : "0"
          }, () => {
            if (this.state.totInvNo > 8000 && this.state.totOrdNo > 10000) {
              toast.info("Invoice Numbers has values more than 8000", { autoClose: 4000 });
              toast.info("Order Control Numbers has values more than 10000", { autoClose: 4000 });
            }
            else if (this.state.totInvNo > 8000)
              toast.info("Invoice Numbers has values more than 8000", { autoClose: 4000 });
            else if (this.state.totOrdNo > 10000)
              toast.info("Order Control Numbers has values more than 10000", { autoClose: 4000 });
          });
        }
      })
      .catch(err => {
        this.setState({ spinner: false });
      })
  }

  requestClick = data => {
    store.dispatch(addTab("Request : " + this.props.urlParams.id, newRequestContainer, { id: this.props.urlParams.id, vendorNo: localStorage.getItem("vendorNumber") }));

  };
  getvalue = (attributeValue, attributeName) => {
    return attributeValue.map(item => item.toString().includes(",") && (attributeName === "Order Control Numbers" || attributeName === "Invoice Numbers") ? item.split(",").map(item => <p style={{ fontSize: "12px" }}>{item}</p>) : <p style={{ fontSize: "12px" }}>{item}</p>);
  };
  render() {
    return (
      <div className="suppWEBContent tab-content">
        <div className="outerbox">
          <div style={{display:'flex',justifyContent:'flex-end'}}>
                  <SyncOutlined className="refreshStyle" style={{float:'right'}}
                    onClick={event => {
                      event.stopPropagation();
                      this.getDocAttrs();
                    }}
                  />
          </div>
          <div className="mainrows">
            <Spin tip="Loading Document details...." spinning={this.state.spinner}>
              <div className="mainBox" style={{paddingTop:'0px'}}>
                <Row>
                  <Col xs={5}>
                    <Col className="Heading">Attributes</Col>
                    <Col className="Table">
                      {this.state.DocumentAttributes.length !== 0 &&
                        this.state.DocumentAttributes[0].attributes.map((value, i) => {

                          return (
                            value.attributeName !== "Total Order Control Numbers" && value.attributeName !== "Total Vendor Invoice Numbers" && <Row className="space">
                              <Col xs={5}>
                                <label style={{ fontSize: "12px" }}>{value.attributeName}</label>{value.attributeName === "Order Control Numbers" ? <span>&nbsp;({this.state.totOrdNo})</span> : value.attributeName === "Invoice Numbers" ? <span>&nbsp;({this.state.totInvNo})</span> : ""}
                              </Col>
                              <Col xs={7}>
                                <div className="form-control">
                                  {value.attributeValue == null
                                    ? ""
                                    : this.getvalue(value.attributeValue, value.attributeName)}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      <Col className="requestBtn">
                        <Button
                          className="btn-primary"
                          onClick={() => {
                            this.requestClick(this.state);
                          }}
                        >
                          Request Info
                      </Button>
                      </Col>
                    </Col>
                  </Col>
                  <Col xs={7}>
                    <Row className="box" id={"pdf-view" + this.state.id.toString()}>
                      {/* <embed src={this.state.SearchUrl} type="application/pdf" width="100%" height="100%" /> */}
                      <PDFObject containerId={"pdf-view" + this.state.id.toString()} url={this.state.SearchUrl} />
                    </Row>
                  </Col>
                </Row>
              </div></Spin>
          </div>
        </div>
      </div>
    );
  }
}

export default DocDetails;
