import * as actionTypes from "../actions/actionTypes";

const INITIAL_STATE = {
    responseDetails: [],
    requestAttachmentData:[]
};

const ResponseDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.RESPONSE_DETAIL_SUCCESS:
            return { ...state, responseDetails: action.payload }
        case actionTypes.REQUEST_ATTACHMENT_DATA :
                return { ...state, requestAttachmentData: action.data };
        default:
            return state;
    }
};

export default ResponseDetailReducer;
