import 'whatwg-fetch';
import { Loader } from '../common/sysco-ui-components/services/commonServices';
import { DP_ENV, authorizationJwt,reCallService } from '../config/apiConfig';
import Axios from 'axios';
import cookie  from 'react-cookies';
import history from './history';
import { toast } from "react-toastify";
import { store } from '../'
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  Loader(false)
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
 
  if (response.status === 401) {
    var dataString = 'grant_type=client_credentials';
    await Axios({
      url:"/token",
      method: "GET",
      headers: {
        "x-env": DP_ENV,
        "authorization": authorizationJwt()
      },
      data:dataString
    })
      .then(res => {
        // Loader(false)
       res.data && cookie.save("token", res.data.access_token, {path: "/" });  
      }).catch(err => {
        // Loader(false)
      })
  }
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.status);
 // console.log(error,"ll")
  error && Loader(false)
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  Loader(true)
  if ( url.includes("token")) {
    return fetch(url, options)
      .then(checkStatus)
      .then(parseJSON);
  } 
 if (!options.headers.authorization || options.headers.authorization == undefined) {
  Loader(false)
    history.push("/")
  } 
  else if (options.headers.authorization && localStorage.getItem("authToken")) { 
   // console.log(url)
   !url.includes("get-customer-service") && !url.includes("get-vendor-download-links")&&!url.includes("get-recent-check")&&!url.includes("daily-pending-payable-report") ?Loader(true):Loader(false)
 
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON);
}
}

export function apigeeTokenGeneration(action) {
  Axios({
    url: "/token",
    method: "GET",
    headers: {
      "x-env": DP_ENV,
      "authorization": authorizationJwt()
    },
    data: 'grant_type=client_credentials'
  }).then(res => {
    res.data && cookie.save("token", res.data.access_token, { path: "/" });
    store.dispatch(reCallService(action));
  })
    .catch(error => {
      try {
        if (error instanceof TypeError) {
          toast.error(generateApigeeErrorMsg('500', error.message), { autoClose: false });
        } else {
          if (error.data != null) {
            toast.error(generateApigeeErrorMsg(error.data.status, error.data.error), { autoClose: false });
          } else {
            toast.error(generateApigeeErrorMsg(error.response.status, error.response.error), { autoClose: false });

          }
        }
      } catch (error) {
        toast.error(generateApigeeErrorMsg(null, error), { autoClose: false });
      }

    })
}

export function generateApigeeErrorMsg(status, error) {
  let errorMessage = `Server call failed. Please try again and re-login if the problem persists.\n 
                      Technical details: Status: ${status}, Error: ${error}`

  return errorMessage
}