//any side effects or async calls

import { put } from "redux-saga/effects";
import request from "../../utils/request";
import { call } from "redux-saga/effects";
import { API_URL_SW, authorization } from "../../config/apiConfig";
import { responseDetailSuccess,requestAttachmentData} from "../actions/responseDetailAction";
import { toast } from "react-toastify";
import {apigeeTokenGeneration }from "../../utils/request"


export function* getDetails(action) {
    const requestURL = `${API_URL_SW}/open-existing-request?requestId=${action.payload}`;
    
    // ${action.payload}
    const requestAttrs = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: authorization()
        },
    };
    try {
        const response = yield call(request, requestURL, requestAttrs);
        let data = response.rows[0].requestDetails;
        yield put(responseDetailSuccess(data ? data : []));
    } catch (err) {
        if (err.toString().includes('401')) {
        apigeeTokenGeneration(action);
           }else{
            toast.error("Failed to load data, please reload.",{
                autoClose: false
              })
          }
        //console.log(err);
    }
}

export function* requestAttachmentSaga(action) {
    const requestURL = API_URL_SW + `/get-attachments?vendorRequestID=${action.payload}`
    // const requestURL = API_URL + `/get-vendor-attachments?vendorRequestID=10189`

    const requestAttrs = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: authorization()
        },
    }

    try {
        const attachmentResponse = yield call(request, requestURL, requestAttrs);
        yield put(requestAttachmentData((attachmentResponse && attachmentResponse.rows[0])?attachmentResponse.rows[0].dataResult:[]))
    } catch (err) {
        if (err.toString().includes('401')) {
           apigeeTokenGeneration(action);
           }
       // console.log(err);
    }
}

