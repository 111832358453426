import * as actionTypes from "./actionTypes";
export function getDocumentDetail(id, catname) {
  return {
    type: actionTypes.GET_DOCUMENT_DETAIL,
    payload: {
      docId: id,
      catName: catname
    }
  };
}
export function documentDetailSuccess(data) {
  return {
    type: actionTypes.DOCUMENT_DETAIL_SUCCESS,
    payload: data
  };
}
