import { API_URL, DP_ENV, authorizationJwt } from "../../../config/apiConfig";
import Axios from "axios";
import { store } from "../../../";
import { toast } from "react-toastify";
import { AUTHORIZATION } from "../config/constant";
import { delTab } from "../store/actions/tabViewAction";
import history from "../../../utils/history";
import cookie from 'react-cookies'
export function downloadReport(url, fileName, type, body,base,snapshot) {

  Loader(true);
  const requestURL = (base?base:API_URL )+ url;
  Axios({
    url: requestURL,
    method: type ? type : "GET",
    responseType: "blob", // important
    headers: {
      authorization:"Bearer "+AUTHORIZATION(),
      "Access-Control-Allow-Origin": "*",
      "env": DP_ENV
    },
    data: body
  })
    .then(response => {
      Loader(false);
      toast.success('File Downloaded Successfully')
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      snapshot==="snapshot" && 
             setTimeout(() => {
              store.dispatch({type:"BATCH_NO"})
               }, 2000);
    })
    .catch(err => {
      Loader(false);
      if(window.location.pathname.includes("suppweb") && err.toString().includes('401')){
        Axios({
          url:"/token",
          method: "GET",
          headers: {
            "x-env": DP_ENV,
            "authorization": authorizationJwt()
          },
          data:'grant_type=client_credentials'
        }).then(res => {
           res.data && cookie.save("token", res.data.access_token, {path: "/"});
           downloadReport(url, fileName, type, body,base,snapshot)
          })
          .catch(err => {
          })
      }else{
        err.message.includes('406')?toast.error('From page greater than total number of pages.Try Again'):toast.error('Download Failed.Try Again')
      }
    });
}

export function Loader(value) {
  store.dispatch({
    type: "LOADING_INDICATIOR",
    data: {
      spinner_status: value
    }
  });
}


export function DeleteTab(value) {
  if(value.tabList && value.tabList[value.tabList.length - 1]&&value.tabList[value.tabList.length - 1].eventKey &&value.activeKey){
    store.dispatch(delTab(value.activeKey))
    history.push({
      pathname:
        "/" +
        value.tabList[
          value.tabList.length - 1
        ].eventKey
    });
  }
}

export const getpagination = (data, b) => {
  let array = [];
  if (!data) {
    return [];
  } else {

    function arr(a) {
      let num = a === 0 ? 5 : a + 15;
      if (num < data) {
        array.push(num);
        arr(num);
      } else {
        array.push(data);
      }
    }
    arr(0);
    if (b) {
      array[0] = b;
      array = array.filter(val => val = val >= b)
    }
  }
  return array;
};