import React, { Component } from 'react';
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import { store } from '../..';
import { ADDTAB } from '../../common/sysco-ui-components/store/actions/actionTypes';
import supplierWebDashboardContainer from '../../containers/supplierWebDashboardContainer';
import history from '../../utils/history';

class Dashboard extends Component {
    static displayName = 'Dashboard';
    componentDidMount(){
        if(localStorage.getItem("authToken") && cookie.load('authToken') != null){ 
            store.dispatch({ type: ADDTAB, payload: { title: "Dashboard", eventKey: supplierWebDashboardContainer, data: {} } })
        }else {
            history.push("/")
        }
    }
    render() {
        return (
            <div>
                
            </div>
        );
    }
}
export default connect()(Dashboard);
