import { SEARCH_ACTION } from "../actions/actionTypes";
import { SearchData, SearchLocateData } from "../actions/searchAction";
import { call, put, takeLatest } from "redux-saga/effects";
import request from "../../utils/request";
import { API_URL_SW, authorization, reCallService ,  DP_ENV, authorizationJwt } from "../../config/apiConfig";
import { Loader } from "../../common/sysco-ui-components/services/commonServices";
import { toast } from "react-toastify";
import {apigeeTokenGeneration }from "../../utils/request"

export function* searchSuppWeb(action) {
  const reqURLSuppResult = `${API_URL_SW}/get-search-results`;
  let data1 = action.data[0].criteria.filter(
    val => val.attrValue.length !== 0
  );
  let data2 = action.data[1].criteria.filter(
    val => val.attrValue.length !== 0
  );
 // console.log(action)
  action.data[0].criteria = data1;
  action.data[1].criteria = data2;
  let formData = new FormData();
  formData.append("query1", JSON.stringify(action.data[0]));
  formData.append("query2", JSON.stringify(action.data[1]));  
  action.data[4] && formData.append("query3", JSON.stringify(action.data[4]));
  action.data[2] && formData.append("query4", JSON.stringify(action.data[2]));
  const requestAttrs1 = {
    method: "POST",
    headers: {
      // "Content-Type": "application/json",
      authorization: authorization()
    },
    body: formData
  };

  try {
    const suppWebResult = yield call(request, reqURLSuppResult, requestAttrs1);
    //console.log(suppWebResult,"hhh")
    if(action.data[3]==="search"){
      yield put(
        SearchData(
          (suppWebResult.rows[0] && suppWebResult.rows[0].searchResultsLists != null)
            ? suppWebResult.rows[0].searchResultsLists
            : []
        )
      );
    }else{
      yield put(
        SearchLocateData(
          (suppWebResult.rows[0] && suppWebResult.rows[0].searchResultsLists != null)
            ? suppWebResult.rows[0].searchResultsLists
            : []
        )
      );
    }
  } catch (err1) {
  //  console.log(err1,err1.toString(),err1.toString().includes("401"),err1.toString().includes(401))
    Loader(false)
    if (err1.toString().includes('401')) {
      apigeeTokenGeneration(action);
    } else{
      yield put(SearchData([]));
      toast.error("Failed to fetch search results, please retry.",{
        autoClose: false
      })
    }
  }
}

// export function* searchSuppwebDetails(action) {
  // const reqURLSuppResult = `${API_URL_SW}/get-document-details?documentType=${action.data}`;

  // const requestAttrs1 = {
  //   method: "GET",
  //   headers: {
  //     "Content-Type": "application/json",
  //     authorization: AUTHORIZATION
  //   }
  // };

  // try {
  //   const suppWebResultData = yield call(
  //     request,
  //     reqURLSuppResult,
  //     requestAttrs1
  //   );
  //   // let newList=[];
  //   // suppWebResult.rows.map((item)=>{
  //   //   newList.push(item.tableContent[0])
  //   // })
  //   // console.log(newList)

  //   yield put(SearchResultData(suppWebResultData.rows[0]));
  // } catch (err1) {}
// }

export default function* suppWebFunction() {
  yield takeLatest(SEARCH_ACTION, searchSuppWeb);
}
