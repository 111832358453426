import React, { Component } from "react";
import "./styles.css";
import images from "../../../../assets/images/index";
import * as actions from "../../store/actions/tabViewAction";
import { connect } from "react-redux";
import { Navbar,Nav,NavItem } from 'react-bootstrap'
import { Image, Glyphicon } from "react-bootstrap/lib";
import history from "../../../../utils/history";
import "antd/dist/antd.css";
import cookie from "react-cookies";
import { Modal, Button } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { store } from "../../../..";
import { CLEAR_TAB } from "../../store/actions/actionTypes";
import Dashboard from "../../../../components/Dashboard/Dashboard";
import supplierWebDashboardContainer from "../../../../containers/supplierWebDashboardContainer";
import { LOGIN_TYPE, LOGIN_DIRECT } from "../../../../config/string";
import { environment, SUPPLIER_SUITE_URL } from "../../config/constant";
import { LANDING } from "../../../../config/path";


const IDLE_TIMEOUT=900;
let _idleSecondsCounter=0;
let lFAIdleTimer;
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: Dashboard,
      collapsed: false,
      width: window.innerWidth,
      user: cookie.load("name") ? cookie.load("name") : "User",
      helpData: [],
      show:true 
    };
  }

  logOut = () => {
    localStorage.clear();
      cookie.remove("token", { path: '/' });
      cookie.remove("userId", { path: '/' });
      cookie.remove("userName", { path: '/' });
      cookie.remove("authToken", { path: '/' });
      cookie.remove("serviceToken", { path: '/' });
      cookie.remove("jwt_token", { path: '/' });
      cookie.remove("name", { path: '/' });
      cookie.remove("accountExpirationTime", { path: '/' });
      cookie.remove("sessionOut", { path: '/' });
      cookie.remove("changeVendorNumbers", { path: '/' });
      store.dispatch({type:CLEAR_TAB})
      store.dispatch({type:"SET_RECENT_CHECKS",payload:[]})
      store.dispatch({type:"SET_PENDING_PAYABLE_CHECK",payload:[]})
      store.dispatch({type:"SET_CUSTOMER_SERVICE",payload:[]})
      store.dispatch({type:"VENDOR_DOWNLOAD_LINK_SUCCESS",payload:[]})
      store.dispatch({type:"SEARCH_DATA",data:[]})
      store.dispatch({type:"LOGIN_FAILED_CHANGE_AFTER_RESET",data:{}})
      store.dispatch({type:"LOGIN_FAILED_PASSWORD_EXPIRED",data:{}})
    if (cookie.load(LOGIN_TYPE) === LOGIN_DIRECT)
      history.push('/')
    else {
      let env = environment();
      history.push('/landing')
      if (env === "-dev")
        window.location.href = SUPPLIER_SUITE_URL.DEV;
      else if (env === "-stg")
        window.location.href = SUPPLIER_SUITE_URL.STG;
      else
        window.location.href = SUPPLIER_SUITE_URL.PRD;
    } 
  };

  componentDidMount() {

    window.onmousemove = function () {
      _idleSecondsCounter = 0;
    };
    window.onkeypress = function () {
      _idleSecondsCounter = 0;
    };
    window.onmousewheel = function () {
      _idleSecondsCounter = 0;
    };
    window.ontouchstart = function () {
      _idleSecondsCounter = 0;
    };
    window.clearInterval(lFAIdleTimer);
    lFAIdleTimer = window.setInterval(this.checkIdleTime, 1000);
  }
  
  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }


  checkIdleTime= () => {
    _idleSecondsCounter++;
    if (_idleSecondsCounter === 2)
    {
      var initialTime = new Date().getTime();
      this.setState({startTime: initialTime})
    }
    var difference=0;
    difference = _idleSecondsCounter >= 2 ? (new Date().getTime() - this.state.startTime) / 1000 : 0;
    if (_idleSecondsCounter === IDLE_TIMEOUT || difference >= IDLE_TIMEOUT) {
      window.clearInterval(lFAIdleTimer);
      clearTimeout(lFAIdleTimer);
      _idleSecondsCounter = 0;
      cookie.save("sessionOut", "true", {path: "/",domain: window.location.hostname});
      this.setState({startTime: 0})
      if(window.location.pathname.includes('dashboard')){
        localStorage.removeItem('authToken')
        Modal.warning({
          title: 'Session TimeOut',
          content: 'Session idle for more than 15 minutes, Please login again...',
          onOk:()=>this.sessionLogout(),
          width:500,
          okText:'LOGOUT',
          keyboard:false
        });
      }
    }
  }

  sessionLogout=()=>{
    // cookie.save('sessionOut', 'true', {path: '/',domain: STORAGE_PATH});
    sessionStorage.clear();
    this.logOut()
  }
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth },()=>{
      this.state.width<=1024 && this.state.width>=768?this.setState({show:false}):this.setState({show:true})
    });
  };
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  handleSelect(key) {
    if (key) {
      if (key.displayName.includes("VendorLogin")) {
        window.location.href = LANDING;
        return;
      }
      this.setState({ activeState: key });
      this.state.width <= 1024 && this.state.width >= 768 && this.setState({ show: false })
      if (key.displayName.includes("Dashboard")) {
        this.props.OnAddTab("Dashboard", supplierWebDashboardContainer);
      } else
        this.props.OnAddTab(key.displayName, key);
    }
  }
  handleClick = val => {
    console.log(val)
    this.setState({
      collapsed: false
    });
    if (val.key !== "logout") {
      this.props.OnAddTab("sdfgds", val.key);
    } else {
      this.logOut();
    }
  };
  closepanel = () => {
    this.setState({
      collapsed: false
    });
  };
  render() {
    return (
      <Navbar id="headerMain" style={{ padding: "0", marginBottom: "0px" }}>
        <div className="header" >
          <div className="customNav">
            <div>
              <Image
                src={images.sysco_white}
                style={{
                  float: "left",
                  height: "45px",
                  paddingTop: "11px",
                  paddingLeft: "11px",
                  marginLeft: "0px"
                }}
                className={"sysco_logo_style"}
              />
              <span className="logo">{this.props.title}</span>

              <div className="header-items">
               <div className='smallSize'>
               {this.state.width<1025 && this.state.width>=768 &&<Button type='primary' style={{margin: '9px',background:'rgb(41 152 208)'}} onClick={()=>{this.setState({show:!this.state.show})}}>
                     {React.createElement(this.state.show ? MenuUnfoldOutlined : MenuFoldOutlined)}
                  </Button>}
                  {this.state.show &&<Navbar inverse collapseOnSelect  >
                  <Navbar.Header>
                   <Navbar.Toggle />
                  </Navbar.Header>
                  <Navbar.Collapse>
                  <Nav bsStyle='pills' stacked activeKey={this.state.activeState} onSelect={k => this.handleSelect(k)} >
                   {this.props.headerItems.map((value, index) => {
                    if (value.title === "Vendor Numbers" && cookie.load("changeVendorNumbers") !== "true"){
                      return;
                    }
                    return (
                           <NavItem eventKey={value.eventKey} href='#'>
                          {value.title}
                         </NavItem>
                    )
                   })}
                  </Nav>
                </Navbar.Collapse>
                </Navbar>}</div>
                <div style={{ float: "right", paddingTop: "12px" }}>

                  &nbsp;&nbsp;&nbsp;
                  <span id="headerUserName" className="header-name">Welcome&nbsp;&nbsp;{this.state.user}</span>
                  &nbsp;&nbsp;
                  <Glyphicon className="user"
                    glyph="user"
                    style={{ color: "white", fontSize: "20px" }}
                  /> &nbsp;&nbsp;
                 <Glyphicon className="off"
                    glyph="off"
                    style={{ fontSize: "20px", color: "white" }}
                    onClick={() => this.logOut()}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    OnAddTab: (title, eventKey) => {
      dispatch(actions.addTab(title, eventKey));
    }
  };
};
export default connect(
  null,
  mapDispatchToProps
)(Header);
