import * as actionTypes from "../actions/actionTypes";
import { takeEvery } from "redux-saga/effects";
import {
  dashboard,
  getRecentChecks,
  getPendingPayableReport,
  getCustomerService,
  getVendorDownloadLink
} from "./dashBoardSaga";
import { login, authLogin, getPortalMessage } from "./loginSaga";
import { createNewRequest, getRequestId, uploadFile } from "./newRequestSaga";

import { getDetails, requestAttachmentSaga } from "./responseDetailSaga";
import { getDocumentDetail } from "./documentDetailSaga";

export function* watchDashboard() {
  yield takeEvery(actionTypes.GET_CUSTOMER_SERVICE, getCustomerService);
  yield takeEvery(actionTypes.DASHBOARD, dashboard);
  yield takeEvery(actionTypes.LOGIN, login);
  yield takeEvery(actionTypes.PORTAL_MESSAGE, getPortalMessage);
  yield takeEvery(actionTypes.AUTH_LOGIN, authLogin);
  yield takeEvery(actionTypes.GET_RECENT_CHECKS, getRecentChecks);
  yield takeEvery(
    actionTypes.GET_PENDING_PAYABLE_CHECK,
    getPendingPayableReport
  );
  yield takeEvery(actionTypes.GET_VENDOR_DOWNLOAD_LINK, getVendorDownloadLink);
  yield takeEvery(actionTypes.CREATE_NEW_REQUEST, createNewRequest);
  yield takeEvery(actionTypes.GET_REQUEST_ID, getRequestId);
  yield takeEvery(actionTypes.GET_RESPONSE_DETAIL, getDetails);
  yield takeEvery(actionTypes.UPLOAD_DATA, uploadFile);
  yield takeEvery(actionTypes.GET_ATTACHMENTS, requestAttachmentSaga);
  //Document detail
  yield takeEvery(actionTypes.GET_DOCUMENT_DETAIL, getDocumentDetail);
}
